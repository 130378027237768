import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from "@angular/core";
import { MwDatatableConfiguration } from "../../components/mw-datatable/mw-datatable.configuration";
import {
  CTDatatableButtonConfiguration,
  CTDatatableConfiguration, CTDatatablePermissions,
  CTGeneralService
} from "@ctsolution/ct-framework";
import { BaseController } from "../../core/controllers/base.controller";
import { MWDateFormatter } from "../../core/lib/date-format.service";
import {
  codiceHeader,
  descrizioneHeader,
  dataPrevistaHeader, statoHeader
} from "./deposit-datatable.headers";
import { DepositService } from "./deposit.service";
import { QueriesService } from "../../core/lib/queries.service";
import { RoleService } from "../../core/lib/role.service";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { DepositStateChangeDialogComponent } from "./deposit-state-change-dialog/deposit-state-change-dialog.component";
import { DepositiApolloResult } from "../../core/interfaces/apollo/depositi.apollo-result";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { DepositoModel } from "../../core/classes/deposito/deposito";
import { BaseApolloQueryClass } from "../../core/classes/apollo/apollo.base.query";
import { ActivatedRoute } from "@angular/router";
import { ApolloResultDataList } from "../../core/interfaces/apollo/apollo.result.data-list";
import { MwDatatableComponent } from "../../components/mw-datatable/mw-datatable.component";
import { StatoDepositoEnum } from "../../core/enum/stato-deposito.enum";
import { iconsBasePath } from "../../core/constants";
import { ModalitaStoccaggio } from "../../core/enum/modalita-stoccaggio.enum";

@Component({
  selector: "app-deposit",
  templateUrl: "./deposit.component.html",
  styleUrls: ["./deposit.component.scss"]
})
export class DepositComponent {

  @ViewChild("packagingCollectionTemplate") packagingCollectionTemplate: TemplateRef<any> | null = null;
  @ViewChild("stateCustomFilter") stateCustomFilter: TemplateRef<any> | null = null;
  @ViewChild("table") table: MwDatatableComponent<DepositoModel | null> | null = null;
  listConfiguration: MwDatatableConfiguration<DepositiApolloResult> | null = null;

  constructor(
    private baseController: BaseController,
    private general: CTGeneralService,
    public roleService: RoleService,
    private cdr: ChangeDetectorRef,
    private _queries: QueriesService,
    public depositService: DepositService,
    private route: ActivatedRoute,
    private dialog: MatLegacyDialog) {
  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        this.setupListConfiguration(qp["depositoId"] ?? null);
        this.cdr.detectChanges();

      });

  }

  setupListConfiguration(globalFilter: string | null) {

    this.listConfiguration = MwDatatableConfiguration
      .create<DepositiApolloResult>()
      .setFetchDataCaller(async (variables: ApolloVariableClass) => {

        const query = await this._queries.entities.depositi();

        if (!query) return null;

        const parameter = BaseApolloQueryClass
          .create()
          .setVariables(variables)
          .setQuery(query);

        return this.baseController.list<DepositiApolloResult>(parameter);

      })
      .setFetchResponseDataMapper((value: DepositiApolloResult): ApolloResultDataList<DepositoModel> => {

        const formattedDepositi = value
          .depositi
          .nodes
          .map((deposito) => {

            const dateService: MWDateFormatter = MWDateFormatter.create();

            const formattedDataPresuntaRicevimentoMerce = dateService.formatDateToString(deposito.dataPresuntaRicevimentoMerce);

            const formattedDataPickup = dateService.formatDateToString(deposito.ritiro?.dataPickup);

            return {
              ...deposito,
              dataPresuntaRicevimentoMerce: formattedDataPresuntaRicevimentoMerce,
              ritiro: {
                ...deposito.ritiro,
                dataPickup: formattedDataPickup
              }
            } as DepositoModel;
          });

        return {
          nodes: formattedDepositi,
          pageInfo: value.depositi.pageInfo,
          totalCount: value.depositi?.totalCount ?? 0
        };

      })
      .setCTDatatableConfiguration(
        CTDatatableConfiguration
          .create<DepositiApolloResult>()
          .setEmptyMessage("Nessun deposito trovato.")
          .setLoadingBody("Caricamento depositi. Attendere prego.")
          .setPermissions([CTDatatablePermissions.insert, CTDatatablePermissions.read])
          .setCreateButtonConfiguration(
            CTDatatableButtonConfiguration
              .create()
              .setIcon("pi pi-truck")
              .setLabel("Spedisci al deposito Most'")
          )
          .setCustomActionTemplate(this.packagingCollectionTemplate)
          .setHeaders([
            statoHeader,
            codiceHeader,
            descrizioneHeader,
            dataPrevistaHeader
          ])
          .setSortField("dataCreazione")
          .setSortOrder(-1)
          .setGlobalFilterValue(globalFilter)
      );

  }

  async manageState(data: DepositoModel) {

    if (!this.roleService.isAdmin()) return; //solo admin può cambiare ruolo

    const model = await this.depositService.getDepositData(data.id);

    this.dialog
      .open(DepositStateChangeDialogComponent, { data: model })
      .afterClosed()
      .subscribe(result => {

        if (result) {

          this.table
            ?.reinit();

        }
      });

  }

  addDeposit() {

    this.general
      .navigateTo(["depositi", "nuovo-deposito"]);

  }

  detail(deposit: DepositoModel): void {

    if (!deposit.id) return;

    this.depositService.showDetail(deposit.id);

  }

  showPackagingCollection(deposit: DepositoModel): void {

    if (!deposit.id) return;

    this.depositService.showPackagingCollection(deposit.id);

  }

  getDepositIcon(value: string) {

    switch (value) {

      case ModalitaStoccaggio.Scatola:
        return `${iconsBasePath}box.svg`;
      case ModalitaStoccaggio.Pallet:
        return `${iconsBasePath}box.svg`;
      default:
        return `${iconsBasePath}bottles.svg`;

    }

  }

  stateFilter(value: StatoDepositoEnum) {

    this
      .table
      ?.datatable
      ?.datatable
      ?.filterGlobal(value, "contains");

  }

  protected readonly iconsBasePath = iconsBasePath;
}
