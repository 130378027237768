import { CTBase } from "@ctsolution/ct-base";
import { MWDateFormatter } from "../lib/date-format.service";
import { TipoClienteEnum } from "../enum/tipo-cliente.enum";
import { GenericReadModel } from "./generic-read.model";
import { IndirizzoModel } from "./indirizzo";

export class ClienteModel extends CTBase<ClienteModel> {

  id: string | null = null;
  tipo: TipoClienteEnum | null = null;
  telefono: string | null = null;
  email: string | null = null;
  codiceFiscale: string | null = null;
  ragioneSociale: string | null = null;
  partitaIva: string | null = null;
  nome: string | null = null;
  cognome: string | null = null;
  dataDiNascita: string | null = null;
  nomeRiferimento: string | null = null;
  codiceRiferimento: string | null = null;
  depositante: GenericReadModel | null = null;
  indirizzoPrincipale: IndirizzoModel | null = null;
  indirizzo?: IndirizzoModel | null = null;

  private constructor();
  private constructor(model?: ClienteModel);
  private constructor(model?: ClienteModel) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: ClienteModel) => new ClienteModel(model);

  setId(value: string | null) {

    this.id = value;
    return this;

  }

  setDataDiNascita(value: string | Date | null) {

    if (value instanceof Date) {

      value = MWDateFormatter
        .create()
        .getDateISOString(value);

    }

    this.dataDiNascita = value;
    return this;

  }

  setTipoCliente(value: TipoClienteEnum | null) {

    this.tipo = value;
    return this;

  }

  setIndirizzo(value: IndirizzoModel | null) {

    this.indirizzoPrincipale = value;

    if (value) {

      if ((<any>value?.stato)?.value) {

        value.stato = (<any>value?.stato)?.value;

      }

      if (value?.stato?.id) {

        (<any>this.indirizzoPrincipale)["StatoId"] = value?.stato?.id ?? null;

      }

    }

    return this;

  }

}
