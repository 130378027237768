<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

  Creazione cliente

  <button
    mat-icon-button color="primary" (click)="close()">

    <mat-icon>close</mat-icon>

  </button>

</div>

<div mat-dialog-content>

  <p-tabView class="tab-view" [(activeIndex)]="tabIndex">

    <p-tabPanel [header]="'Dati generali'">

      <form [formGroup]="form">

        <div>

          <app-customer-data-form [configuration]="configuration"></app-customer-data-form>

        </div>

        <div>

          <h3> {{ configuration.tipoCliente === TipoClienteEnum.ClienteBusiness ? 'Sede legale' : 'Residenza' }}</h3>

          <app-address-data-form></app-address-data-form>

        </div>

      </form>

    </p-tabPanel>

    <p-tabPanel [header]="'Indirizzi destinatari'" *ngIf="addressesDatatableConfiguration.cliente">

      <app-addresses-datatable [configuration]="addressesDatatableConfiguration"></app-addresses-datatable>

    </p-tabPanel>

  </p-tabView>

</div>

<div mat-dialog-actions align="end" *ngIf="tabIndex === 0">

  <ct-button [configuration]="submitButton"></ct-button>

</div>
