<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

  {{ 'CT_GENERAL.create-packs' | translate }}

  <button mat-icon-button color="primary" (click)="close()">

    <mat-icon>close</mat-icon>

  </button>

</div>

<div mat-dialog-content>

  <form [formGroup]="form">

    <div class="box-composer-container">

      <div fxLayout="row wrap" class="m-t-10">

        <div fxFlex="100" fxFlex.gt-md="40">

          <p>Seleziona la tipologia di scatola che vuoi confezionare:</p>

          <app-package-select-control #packageSelectControlComponent></app-package-select-control>

          <p>Scegli i vini che desideri includere nella tua confezione:</p>

          <div fxLayout="row wrap">

            <div [fxFlex]="validProductLookupControl ? 60 : 80">

              <app-product-stock-lookup-control
                #productStockLookupControl></app-product-stock-lookup-control>

            </div>

            <div fxFlex="20" [hidden]="!validProductLookupControl">

              <app-qta-control #qtaControlComponent [configuration]="qtaControlConfiguration"></app-qta-control>

            </div>

            <div fxFlex="20" align="end">

              <button
                mat-button
                class="general-button add-wine-button"
                (click)="addWine()"> {{ 'Aggiungi' | translate }}
              </button>

            </div>

          </div>

          <div style="min-height: 285px;">

            <div class="selected-items-container" [hidden]="!selectedItemsSections.length">

              <p>Vini selezionati:</p>

              <app-section-detail-data
                *ngFor="let section of selectedItemsSections"
                [configuration]="section"></app-section-detail-data>

            </div>

          </div>

        </div>

        <div fxFlex="100" fxFlex.gt-md="60" class="p-10 infografica-container">

          <mw-wine-box-infographic #infographic></mw-wine-box-infographic>

        </div>

      </div>

    </div>

  </form>

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">

  <ct-button [configuration]="cancelButton"></ct-button>

  <ct-button [configuration]="submitButton"></ct-button>

</div>
