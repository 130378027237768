import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";

export const clienteHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("cliente.nomeRiferimento")
  .setLabel("Cliente")
  .setInlineStyle("min-width: 150px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per cliente")
      .setShowMenu(true));

export const nomeDestinatarioHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("nomeDestinatario")
  .setLabel("Destinatario")
  .setInlineStyle("min-width: 200px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per destinatario")
      .setShowMenu(true));

export const indirizzoHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzo.via")
  .setLabel("Indirizzo")
  .setSortable(true)
  .setInlineStyle("min-width: 300px;")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per indirizzo")
      .setShowMenu(true));

export const statoHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzo.stato.nome")
  .setLabel("Stato")
  .setSortable(true)
  .setInlineStyle("min-width: 150px;")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per stato")
      .setShowMenu(true));
