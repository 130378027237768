<div class="personal-profile-cntr m-t-15 m-b-30">

  <div fxLayout="row wrap">

    <div fxFlex="100">

      <div class="personal-profile-spacer"></div>

    </div>

    <div class="information" fxFlex="100">

      <h1 class="personal-name"> {{ model?.nomeRiferimento ?? model?.ragioneSociale }} </h1>

      <div class="m-b-15" fxLayout="row" fxLayoutAlign.gt-xs="space-between start">

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <div fxLayout="row" fxLayoutAlign="start center">

            <mat-icon>person_check</mat-icon>

            <h3>Dati di riferimento</h3>

          </div>

          <p *ngIf="model?.codiceFiscale"><strong>Codice Fiscale</strong>: {{ model?.codiceFiscale }}</p>

          <p><strong>Ragione Sociale</strong>: {{ model?.ragioneSociale }}</p>

          <p *ngIf="model?.partitaIva"><strong>Partita IVA</strong>: {{ model?.partitaIva }}</p>

        </div>

        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="33.33">

          <div fxLayout="row" fxLayoutAlign="start center">

            <mat-icon>home</mat-icon>

            <h3>Sede legale</h3>

          </div>

          <p> {{ model?.indirizzo?.via }}, {{ model?.indirizzo?.comune }}
            , {{ model?.indirizzo?.cap }}, {{ model?.indirizzo?.provincia }}
          </p>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <div fxLayout="row" fxLayoutAlign="start center">

            <mat-icon class="m-r-5">contact_mail</mat-icon>
            <h3>Contatti</h3>

          </div>

          <p><strong>Email</strong>: {{ model?.email }}</p>

          <p><strong>Telefono</strong>: {{ model?.telefono }}</p>

        </div>

      </div>

      <div class="actions">

        <div>

          <button class="general-button m-b-20" mat-button (click)="openCondizioniContrattuali()"> Condizioni
            contrattuali
          </button>

        </div>

        <div align="end">

          <button class="general-button m-b-20 m-r-15" mat-button (click)="updateAddress()"> Modifica sede legale
          </button>

          <button class="general-button m-b-20" mat-button (click)="updatePassword()"> Cambio password</button>

        </div>

      </div>

    </div>

  </div>

</div>
