import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddressesComponent } from "./addresses.component";
import { RouterModule } from "@angular/router";
import { AddressesDatatableModule } from "./addresses-datatable/addresses-datatable.module";

@NgModule({
  declarations: [
    AddressesComponent
  ],
  exports: [
    AddressesComponent
  ],
  imports: [
    CommonModule,
    AddressesDatatableModule,
    RouterModule.forChild([
      {
        path: "",
        component: AddressesComponent,
        data: {
          title: "Destinatari",
          urls: []
        }
      }
    ])
  ]
})
export class AddressesModule {
}
