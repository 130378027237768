import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { MwDatatableConfiguration } from "../../../components/mw-datatable/mw-datatable.configuration";
import { BaseController } from "../../../core/controllers/base.controller";
import { CustomerDatatableConfiguration } from "./customer-datatable.configuration";
import {
  indirizzoPrincipaleHeader, indirizzoPrincipaleProvinciaHeader, indirizzoPrincipaleStatoHeader,
  nominativoHeader,
  ragioneSocialeHeader
} from "./customer-datatable.headers";
import { CustomerService } from "../customer.service";
import {
  CTDatatableButtonConfiguration,
  CTDatatableConfiguration,
  CTDatatableHeaderCellConfiguration, CTDatatablePermissions
} from "@ctsolution/ct-framework";
import { QueriesService } from "../../../core/lib/queries.service";
import { ApolloVariableClass } from "../../../core/classes/apollo/apollo.variable";
import { ClientiApolloResult } from "../../../core/interfaces/apollo/clienti.apollo-result";
import { ClienteModel } from "../../../core/classes/cliente";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";
import { MwDatatableComponent } from "../../../components/mw-datatable/mw-datatable.component";
import { IndirizzoModel } from "../../../core/classes/indirizzo";

@Component({
  selector: "app-customer-datatable",
  templateUrl: "./customer-datatable.component.html"
})
export class CustomerDatatableComponent<T> {

  @Input() configuration: CustomerDatatableConfiguration | null = null;
  @ViewChild("table") table: MwDatatableComponent<ClienteModel | null> | null = null;

  listConfiguration: MwDatatableConfiguration<ClientiApolloResult> = MwDatatableConfiguration
    .create<ClientiApolloResult>()
    .setFetchDataCaller(async (variables: ApolloVariableClass) => {

      variables
        .setTipoCliente(this.configuration?.customerType!);

      const query = await this._queries.entities.clienti();

      if (!query) return null;

      const parameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(query);

      return this.controller.list<ClientiApolloResult>(parameter);

    })
    .setFetchResponseDataMapper((value: ClientiApolloResult) => value.clienti)
    .setCTDatatableConfiguration(
      CTDatatableConfiguration
        .create<ClientiApolloResult>()
        .setEmptyMessage("Nessun cliente trovato.")
        .setLoadingBody("Caricamento dati clienti. Attendere prego.")
        .setPermissions([CTDatatablePermissions.insert, CTDatatablePermissions.update, CTDatatablePermissions.read])
        .setCreateButtonConfiguration(
          CTDatatableButtonConfiguration
            .create()
            .setIcon("pi pi-user-plus")
            .setLabel("Aggiungi cliente")
        )
    );

  constructor(
    private controller: BaseController,
    private customerService: CustomerService,
    private _queries: QueriesService,
    private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    const columns: CTDatatableHeaderCellConfiguration[] = [];

    const addressHeader = indirizzoPrincipaleHeader();

    if (this.configuration?.isBusiness) {

      columns
        .push(ragioneSocialeHeader);

      addressHeader
        .setLabel("Indirizzo Sede Legale");

    } else {

      columns.push(nominativoHeader);

    }

    columns
      .push(addressHeader, indirizzoPrincipaleProvinciaHeader, indirizzoPrincipaleStatoHeader);

    this.listConfiguration
      .CTDatatableConfiguration
      ?.setHeaders(columns);

    this.cdr.detectChanges();

  }

  getFormattedIndirizzoPrincipale(value: IndirizzoModel | null) {

    if (!value) return "";

    return [value.via, value.cap, value.comune].filter(elm => !!elm).join(", ");

  }

  async onSave(event: ClienteModel | null) {

    this.customerService
      .setupCustomer(this.configuration?.customerType!, event)
      .then(success => {

        if (success) {

          this.table
            ?.reinit();

        }

      });

  }

  detail(customer: ClienteModel) {

    if (!customer.id) return;

    this.customerService
      .getCustomerDialogDetail(customer.id);

  }

}
