import { TipoClienteEnum } from "../../enum/tipo-cliente.enum";
import { ApolloOrderInfo } from "../../interfaces/apollo/apollo.order-info";
import { ApolloFilters } from "../../interfaces/apollo/apollo.filters";

export class ApolloVariableClass {

  id: string | null = null;
  depositanteId: string | null = null;
  tipoCliente: TipoClienteEnum | null = null; // può essere: ClientePrivate, ClienteBusiness o null (torna tutto)
  vinoId: string | null = null; // serve per le query di giacenza dirette
  clienteId: string | null = null;

  fromDateTime: Date | null = null;
  toDateTime: Date | null = null;

  after: string | null = null;
  searchText: string = ""; // non è nullable
  first: number | null = 10;

  whereAndFilters: Array<ApolloFilters> | null = [];
  order: Array<ApolloOrderInfo> = [];

  constructor() {
  }

  public static create = (): ApolloVariableClass => new ApolloVariableClass();

  setId(value: string | null): ApolloVariableClass {

    this.id = value;
    return this;

  }

  setDepositanteId(value: string | null): ApolloVariableClass {

    this.depositanteId = value;
    return this;

  }

  setTipoCliente(value: TipoClienteEnum | null): ApolloVariableClass {

    this.tipoCliente = value;
    return this;

  }

  setClienteId(value: string | null): ApolloVariableClass {

    this.clienteId = value;
    return this;

  }

  setSearchText(value: string = ""): ApolloVariableClass {

    this.searchText = value;
    return this;

  }

  setFirst(value: number | null): ApolloVariableClass {

    this.first = value;
    return this;

  }

  setWhereAndFilters(value: Array<ApolloFilters> | null): ApolloVariableClass {

    this.whereAndFilters = value;
    return this;

  }

  setOrder(value: Array<ApolloOrderInfo> = []): ApolloVariableClass {

    this.order = value;
    return this;

  }

  setAfter(value: string | null): ApolloVariableClass {

    this.after = value;
    return this;

  }

  setVinoId(value: string | null): ApolloVariableClass {

    this.vinoId = value;
    return this;

  }

  setFromDateTime(value: Date | null): ApolloVariableClass {

    this.fromDateTime = value;
    return this;

  }

  setToDateTime(value: Date | null): ApolloVariableClass {

    this.toDateTime = value;
    return this;

  }

}
