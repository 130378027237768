import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProductComponent } from "./product.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CtButtonModule, CtCardModule, CTDatatableModule } from "@ctsolution/ct-framework";
import { RouterModule } from "@angular/router";
import { MwDatatableModule } from "../../components/mw-datatable/mw-datatable.module";
import { MatDialogModule } from "@angular/material/dialog";
import { WineStockModule } from "../stock/wine-stock/wine-stock.module";
import { WineCreationFormModule } from "./wine-creation-form/wine-creation-form.module";

@NgModule({
  declarations: [ProductComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    CtButtonModule,
    CtCardModule,
    MatDialogModule,
    WineCreationFormModule,
    RouterModule.forChild([
      {
        path: "giacenze",
        loadChildren: () => WineStockModule
      },
      {
        path: "",
        component: ProductComponent,
        data: {
          title: "I tuoi vini",
          urls: []
        }
      }
    ]),
    MwDatatableModule,
    CTDatatableModule
  ]
})
export class ProductModule {
}
