import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";

export const descrizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("descrizione")
  .setLabel("Descrizione")
  .setInlineStyle("min-width: 450px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per descrizione")
      .setShowMenu(true));

export const tipoVinoHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("tipoVino.nome")
  .setLabel("Tipo vino")
  .setInlineStyle("min-width: 300px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per tipo vino")
      .setShowMenu(true));

export const denominazioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("denominazione.nome")
  .setLabel("Denominazione")
  .setInlineStyle("min-width: 230px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per denominazione")
      .setShowMenu(true));

export const annoHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("anno")
  .setLabel("Anno")
  .setInlineStyle("min-width: 140px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per anno")
      .setShowMenu(true));

export const menzioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("menzione.nome")
  .setLabel("Menzione")
  .setInlineStyle("min-width: 180px;")
  .setFilter(CTDatatableFilterConfiguration
    .create()
    .setPlaceHolder("Cerca per menzione"));

export const produttoreHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("produttore")
  .setLabel("Produttore")
  .setInlineStyle("min-width: 180px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per produttore")
      .setShowMenu(true));

export const tipologiaHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("tipologia.nome")
  .setLabel("Tipologia")
  .setInlineStyle("min-width: 200px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per tipologia")
      .setShowMenu(true));

export const paeseHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("regione.stato.nome")
  .setLabel("Paese")
  .setInlineStyle("min-width: 150px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per paese")
      .setShowMenu(true));

export const gradazioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("gradazione")
  .setLabel("Gradazione")
  .setInlineStyle("min-width: 200px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setType("numeric")
      .setPlaceHolder("Cerca per gradazione")
      .setShowMenu(true));
