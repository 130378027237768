<mw-datatable
  #table
  class="customer-datatable-container"
  [configuration]="listConfiguration"
  (onSave)="onSave($event)"
  (onDetail)="detail($event)">

  <ng-template #body let-value="value">

    <td>
      {{ value?.nomeRiferimento ?? '' }}
    </td>

    <td>

      {{ getFormattedIndirizzoPrincipale(value?.indirizzoPrincipale) }}

    </td>

    <td>

      {{ value?.indirizzoPrincipale?.provincia ?? '' }}

    </td>

    <td>

      {{ value?.indirizzoPrincipale?.stato?.nome ?? '' }}

    </td>

  </ng-template>

</mw-datatable>
