import { CtControlConfiguration, CtControlTypes, CtThemeTypes, Dictionary } from "@ctsolution/ct-framework";

export const ANNO_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
  name: "anno",
  label: "Anno",
  type: CtControlTypes.NUMBER,
  validators: [
    { name: "required" }
  ],
  flexSpacePercentage: 33.33,
  flexSpaceBreakpoints: <Dictionary<number>>{
    xs: 100,
    sm: 100
  },
  theme: CtThemeTypes.MATERIAL
};
