import { Component, ViewChild } from "@angular/core";
import { CustomerDatatableConfiguration } from "./customer-datatable/customer-datatable.configuration";
import { BaseController } from "../../core/controllers/base.controller";
import { QueriesService } from "../../core/lib/queries.service";
import { CustomerDatatableComponent } from "./customer-datatable/customer-datatable.component";
import { TipoClienteEnum } from "src/app/core/enum/tipo-cliente.enum";
import { ApolloVariableClass } from "src/app/core/classes/apollo/apollo.variable";
import { ClientiApolloResult } from "../../core/interfaces/apollo/clienti.apollo-result";
import { BaseApolloQueryClass } from "src/app/core/classes/apollo/apollo.base.query";

@Component({
  selector: "app-customer",
  template: `
    <p-tabView #tabView class="tab-view">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span
            class="counter">{{ privateCustomerDatatable?.listConfiguration?.CTDatatableConfiguration?.totalRecords }}</span>
          Privati
          <img src="/assets/images/icons/customer.svg">
        </ng-template>
        <app-customer-datatable
          #privateCustomerDatatable
          [configuration]="privateDatatableConfiguration"></app-customer-datatable>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span
            class="counter">{{ businessCustomerDatatable?.listConfiguration?.CTDatatableConfiguration?.totalRecords }}</span>Business
          <img src="/assets/images/icons/customer-business.svg">
        </ng-template>
        <app-customer-datatable
          #businessCustomerDatatable
          [configuration]="businessDatatableConfiguration"></app-customer-datatable>
      </p-tabPanel>
    </p-tabView>`
})
export class CustomerComponent {

  @ViewChild("businessCustomerDatatable") businessCustomerDatatable: CustomerDatatableComponent<any> | null = null;

  privateDatatableConfiguration: CustomerDatatableConfiguration = CustomerDatatableConfiguration
    .create()
    .setCustomerType(TipoClienteEnum.ClientePrivate);

  businessDatatableConfiguration: CustomerDatatableConfiguration = CustomerDatatableConfiguration
    .create()
    .setCustomerType(TipoClienteEnum.ClienteBusiness);

  constructor(private _queries: QueriesService, private baseController: BaseController) {
  }

  ngAfterViewInit() {

    if (this.businessCustomerDatatable) {

      this.setupCustomerDatatableCount(this.businessCustomerDatatable, TipoClienteEnum.ClienteBusiness);

    }

  }

  /*
  * Questo serve perchè il cliente vuole vedere nell'header della tabella il count totale di clienti business e privati.
  * Primeng la seconda tab non la carica finchè non la clicco, e di conseguenza non ne carica nemmeno il contenuto
  * dunque è necessario fare questa chiamata per ottenere il count totale degli utenti business
  * */
  async setupCustomerDatatableCount(datatable: CustomerDatatableComponent<any>, tipoCliente: TipoClienteEnum) {

    const variables = ApolloVariableClass
      .create()
      .setTipoCliente(tipoCliente);

    const query = await this._queries.entities.clienti();

    if (!query) return null;

    const parameter = BaseApolloQueryClass
      .create()
      .setVariables(variables)
      .setQuery(query);

    const fetcher = await this.baseController.list<ClientiApolloResult>(parameter);

    fetcher
      ?.subscribe(response => datatable.listConfiguration.CTDatatableConfiguration?.setTotalRecord(response.clienti.totalCount));

  }


}
