import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { DepositCreateStep2Component } from "./deposit-create-step2/deposit-create-step2.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DepositCreateStep1Component } from "./deposit-create-step1/deposit-create-step1.component";
import { DepositCreateStep3Component } from "./deposit-create-step3/deposit-create-step3.component";
import { ModalitaStoccaggio } from "../../../core/enum/modalita-stoccaggio.enum";
import { distinctUntilChanged, pairwise } from "rxjs";
import { DepositCreateStep4Component } from "./deposit-create-step4/deposit-create-step4.component";
import { BaseController } from "../../../core/controllers/base.controller";
import { DepositiControllerRequest } from "../../../core/controllers/controllers";
import { SnackBarService } from "../../../core/lib/snakbar.service";
import { CTGeneralService } from "@ctsolution/ct-framework";
import { DepositoModel } from "../../../core/classes/deposito/deposito";
import { DepositFormDTO } from "./deposit-create-wizard.interfaces";
import { BaseRequestClass } from "../../../core/classes/apollo/apollo.base.request";
import { RitiroDeposito } from "../../../core/classes/deposito/ritiro-deposito";
import { CourierRateTableService } from "../../../components/courier-rate-table/courier-rate-table.service";

@Component({
  selector: "app-deposit-create-wizard",
  templateUrl: "./deposit-create-wizard.component.html"
})
export class DepositCreateWizardComponent {

  @ViewChild("step1") depositCreateStep1: DepositCreateStep1Component | null = null;
  @ViewChild("step2") depositCreateStep2: DepositCreateStep2Component | null = null;
  @ViewChild("step3") depositCreateStep3: DepositCreateStep3Component | null = null;
  @ViewChild("step4") depositCreateStep4: DepositCreateStep4Component | null = null;

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private generalService: CTGeneralService,
    private baseController: BaseController,
    private cdr: ChangeDetectorRef,
    private courierRateTableService: CourierRateTableService
  ) {

    this.form = this.formBuilder.group({});

    this.form
      .valueChanges
      .pipe(pairwise(), distinctUntilChanged())
      .subscribe(([prev, next]: [any, any]) => {

        const depositPackagingType: ModalitaStoccaggio = next?.step1?.depositPackagingType;

        if (this.depositCreateStep2?.packageComposerConfiguration.depositPackagingType !== depositPackagingType) {

          this.depositCreateStep2
            ?.setup(depositPackagingType);

        }

      });

  }

  ngAfterViewInit() {

    this.form
      .addControl("step1", this.depositCreateStep1?.form);

    this.form
      .addControl("step2", this.depositCreateStep2?.form);

    this.form
      .addControl("step3", this.depositCreateStep3?.form);

    this.form
      .addControl("step4", this.depositCreateStep4?.form);

  }

  onSelectionChange(event: any) {

    switch (event.selectedIndex) {

      case 1:
        // il reset dello step2 lo faccio sul form change così che non si verifichi il glitch sgradevole di composizione del form dopo la transizione dello stepper
        break;

      case 3:

        this.depositCreateStep4
          ?.setup(this.form.getRawValue());

        break;

      case 4:
        this.submit();
        break;

    }

    this.cdr.detectChanges();

  }

  async submit() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const formValue = this.form.getRawValue() as DepositFormDTO;

      const depositoModel: DepositoModel = DepositoModel
        .create()
        .setupByForm(formValue);

      const isRitiroMode = !!depositoModel.ritiro;

      if (isRitiroMode) {

        const shipmentData = formValue.step4.datiAggiuntivi.shipmentAddressData;

        const ritiroDepositoModel: RitiroDeposito = RitiroDeposito
          .create(
            shipmentData?.contatto.nomeDestinatario ?? "",
            shipmentData?.contatto.telefonoDestinatario ?? "",
            shipmentData?.contatto.emailDestinatario ?? "")
          .setIndirizzo(shipmentData?.indirizzo);

        ritiroDepositoModel
          .setColli(depositoModel.colliRitiro);

        const rate = await this.courierRateTableService.open(ritiroDepositoModel);

        if (!rate) return;

        depositoModel
          .ritiro
          ?.setCorriereId(rate.idCorriere)
          ?.setTariffaId(rate.id)
          ?.setMittenteFromRitiroDepositoModel(ritiroDepositoModel);

      }

      const parameter: BaseRequestClass = DepositiControllerRequest()
        .setDataSource(depositoModel);

      const caller = await this.baseController.executeDepositorEntityRequest<DepositoModel>(parameter);

      if (!caller) return;

      caller
        .subscribe(() => {

          this.snackBarService.generalMessage("CT_GENERAL.added_deposit_success");

          this.generalService.navigateTo(["depositi"]);

        });

    }

  }

}
