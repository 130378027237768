import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { IndirizzoSpedizioneModel } from "../../../core/classes/spedizione/indirizzo";
import { CtFormConfiguration } from "@ctsolution/ct-framework";
import { RecipientContactDataFormConfiguration } from "./recipient-contact-data-form.configuration";

@Component({
  selector: "app-recipient-contact-data-form",
  template: `
    <ct-form [configuration]="formConfiguration"></ct-form>`
})
export class RecipientContactDataFormComponent {

  form: FormGroup;
  formConfiguration: CtFormConfiguration;

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});
    this.formConfiguration = CtFormConfiguration.create(this.form, RecipientContactDataFormConfiguration);

  }

  ngAfterViewInit() {

  }

  setupValues(value: IndirizzoSpedizioneModel) {

    this.form
      .patchValue(value);

  }


}
