import { CtControlConfiguration, CtControlTypes, CtThemeTypes } from "@ctsolution/ct-framework";

export const PROVINCIA_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
  name: "provincia",
  label: "Provincia",
  type: CtControlTypes.TEXT,
  validators: [
    { name: "required" },
    { name: "minlength", value: 2 },
    { name: "maxlength", value: 2 }  ],
  theme: CtThemeTypes.MATERIAL
};
