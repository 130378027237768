import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";

export const nominativoHeader = CTDatatableHeaderCellConfiguration
  .create()
  .setField("nomeRiferimento")
  .setLabel("Nominativo")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per nominativo")
      .setShowMenu(true));

export const ragioneSocialeHeader = CTDatatableHeaderCellConfiguration
  .create()
  .setField("nomeRiferimento")
  .setLabel("Ragione Sociale")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per ragione sociale")
      .setShowMenu(true));

export const indirizzoPrincipaleHeader = () => CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzoPrincipale.via")
  .setLabel("Indirizzo Residenza")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per indirizzo")
      .setShowMenu(true));

export const indirizzoPrincipaleProvinciaHeader = CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzoPrincipale.provincia")
  .setLabel("Provincia")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per provincia")
      .setShowMenu(true));

export const indirizzoPrincipaleStatoHeader = CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzoPrincipale.stato.nome")
  .setLabel("Stato")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per stato")
      .setShowMenu(true));
