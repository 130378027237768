import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DepositComponent } from "./deposit.component";
import { RouterModule } from "@angular/router";
import { CtButtonModule, CTDatatableModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MwDatatableModule } from "../../components/mw-datatable/mw-datatable.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { DepositCreateWizardModule } from "./deposit-create-wizard/deposit-create-wizard.module";
import { MatDialogModule } from "@angular/material/dialog";
import { DepositService } from "./deposit.service";
import { BulletStateModule } from "../../components/bullet-state/bullet-state.module";
import { DepositStateChangeDialogComponent } from "./deposit-state-change-dialog/deposit-state-change-dialog.component";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { TextFieldModule } from "@angular/cdk/text-field";
import { ReactiveFormsModule } from "@angular/forms";
import { PalletStockModule } from "../stock/pallet-stock/pallet-stock.module";
import { MWDateFormatter } from "../../core/lib/date-format.service";
import {
  DepositStateChangeFormComponent
} from "./deposit-state-change-dialog/deposit-state-change-form/deposit-state-change-form.component";
import {
  DepositStateChangeNoteHistoryComponent
} from "./deposit-state-change-dialog/deposit-state-change-note-history/deposit-state-change-note-history.component";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { MultiSelectModule } from "primeng/multiselect";
import { DepositStateSelectFilterModule } from "./deposit-state-select-filter/deposit-state-select-filter.module";
import { ModelDetailModule } from "../../components/model-detail/model-detail.module";
import { NoteControlModule } from "../../components/note-control/note-control.module";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { StateLegendModule } from "../../components/state-legend/state-legend.module";

@NgModule({
  declarations: [DepositComponent, DepositStateChangeDialogComponent, DepositStateChangeFormComponent, DepositStateChangeNoteHistoryComponent],
  imports: [
    CommonModule,
    CtButtonModule,
    FlexLayoutModule,
    MwDatatableModule,
    MatDialogModule,
    RouterModule.forChild([
      {
        path: "giacenze",
        loadChildren: () => PalletStockModule
      },
      {
        path: "nuovo-deposito",
        loadChildren: () => DepositCreateWizardModule
      },
      {
        path: "",
        component: DepositComponent,
        data: {
          title: "I tuoi depositi",
          urls: []
        }
      },
      {
        path: "**",
        redirectTo: "",
        pathMatch: "full"
      }
    ]),
    TranslateModule,
    MatIconModule,
    MatLegacyDialogModule,
    CTDatatableModule,
    BulletStateModule,
    MatLegacyButtonModule,
    MatLegacyOptionModule,
    MatLegacySelectModule,
    MatLegacyInputModule,
    TextFieldModule,
    ReactiveFormsModule,
    MatCardModule,
    MatMenuModule,
    MultiSelectModule,
    DepositStateSelectFilterModule,
    ModelDetailModule,
    NoteControlModule,
    ButtonModule,
    RippleModule,
    StateLegendModule
  ],
  providers: [DepositService, MWDateFormatter]
})
export class DepositModule {
}


