import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";

export const depositoHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("deposito")
  .setLabel("Deposito")
  .setInlineStyle("min-width: 200px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per deposito")
      .setShowMenu(true));

export const spedizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("spedizione")
  .setLabel("Spedizione")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per spedizione")
      .setShowMenu(true));

export const palletHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("pallet")
  .setLabel("Pallet")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per pallet")
      .setShowMenu(true));
