import {
  CtControlConfiguration, CtControlTypes,
  CtFormConfiguration,
  Dictionary
} from "@ctsolution/ct-framework";
import { CustomerControls } from "./customer.controls";

const GenericCustomerControls: CtControlConfiguration[] = [
  <CtControlConfiguration>{
    name: "nome",
    label: "CT_GENERAL.name",
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 20 }
    ]
  },
  <CtControlConfiguration>{
    name: "cognome",
    label: "CT_GENERAL.surname",
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 40 }
    ]
  },
  <CtControlConfiguration>{
    name: "dataDiNascita",
    label: "CT_GENERAL.birthday",
    type:
    CtControlTypes.DATE,
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" }
    ]
  }
];

export const GenericCustomerData = (): CtFormConfiguration => <CtFormConfiguration>{
  controls: [...GenericCustomerControls, ...CustomerControls]
};
