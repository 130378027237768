import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CtButtonConfiguration, CtFormConfiguration } from '@ctsolution/ct-framework';
import { DestinazioneModel } from 'src/app/core/classes/destinazione';
import { ProfileBusinessUpdateFormConfiguration, ProfilePrivateUpdateFormConfiguration } from './profille-update-configuration';
import { AddressDataFormComponent } from 'src/app/components/shipment-address-form/address-data-form/address-data-form.component';
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { BaseController } from 'src/app/core/controllers/base.controller';
import { DepositantiControllerRequest } from 'src/app/core/controllers/controllers';

@Component({
  selector: 'app-profile-update-dialog',
  templateUrl: './profile-update-dialog.component.html',
  styleUrls: ['./profile-update-dialog.component.scss']
})
export class ProfileUpdateDialogComponent {

  formConfiguration: CtFormConfiguration

  @ViewChild(AddressDataFormComponent) addressDataFormComponent: AddressDataFormComponent | null = null;

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CONFIRM_DIALOG.save")
    .setClass("general-button accept-confirm")
    .setAction(() => this.submit());

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CONFIRM_DIALOG.cancel")
    .setClass("general-button cancel-confirm")
    .setAction(() => this.close());

  stateCreate = true;

  constructor(private baseController : BaseController,private formBuilder: FormBuilder, @Inject(MAT_LEGACY_DIALOG_DATA) public model: DestinazioneModel | null, private dialogRef : MatDialogRef<ProfileUpdateDialogComponent>) {

    this.formConfiguration = CtFormConfiguration.create(this.formBuilder.group({}), ProfileBusinessUpdateFormConfiguration);

  }

  ngAfterViewInit(): void {

    if (this.model) {
      this.setup(this.model);
      this.addressDataFormComponent?.setupValues(this.model.indirizzo, false);
      this.stateCreate = false;
    }

  }



  submit() {

    this.formConfiguration.form?.markAllAsTouched();

    if(this.formConfiguration.form?.valid)
    {
      const model : DestinazioneModel = this.formConfiguration.form?.value;
      model.indirizzo = this.addressDataFormComponent?.form.value;

      const paramater = DepositantiControllerRequest();

      this.baseController.createOrUpdate(model,paramater).then(() => this.dialogRef.close(true));
    }


  }

  close() { 
    this.dialogRef.close();
  }

  setup(model: DestinazioneModel) {

    if(model.partitaIva)
      this.formConfiguration.form?.patchValue(model);
    else{
      this.formConfiguration = CtFormConfiguration.create(this.formBuilder.group({}), ProfilePrivateUpdateFormConfiguration);
      this.formConfiguration.form?.patchValue(model);

    }
  

  }

  onChange(event: any) {

    switch (event.value) {

      case 1:
      this.formConfiguration = CtFormConfiguration.create(this.formBuilder.group({}), ProfileBusinessUpdateFormConfiguration);
      break;

      case 2:
      this.formConfiguration = CtFormConfiguration.create(this.formBuilder.group({}), ProfilePrivateUpdateFormConfiguration);
      break;

    }
  }

}
