<mat-card class="stocks-counter-container">
  <mat-card-content>
    <!-- column -->
    <div class="stats">
      <h3 class="m-0">Le tue giacenze</h3>
      <div fxLayout="row" fxLayout.md="column" fxLayout.xs="column">

        <div class="p-10" fxLayout="row" fxFlex="50">
          <img class="img-style m-r-5" [src]="'/assets/images/icons/pallet.svg'">
          <div>
            <div class="font-light">
              Pallet
            </div>
            <b>{{ viewModel[ModalitaStoccaggio.Pallet] }}</b>
          </div>
        </div>

        <div class="p-10" fxLayout="row" fxFlex="50">
          <img class="img-style m-r-5" [src]="'/assets/images/icons/bottles.svg'">
          <div>
            <div class="font-light">
              Vini
            </div>
            <b>{{ viewModel[ModalitaStoccaggio.Bottiglia] }}</b>
          </div>
        </div>

      </div>

    </div>

    <div class="sitea"></div>

  </mat-card-content>

</mat-card>
