import { CtControlConfiguration, CtControlTypes, CtThemeTypes, Dictionary } from "@ctsolution/ct-framework";

export const GRADAZIONE_ALCOLICA: CtControlConfiguration = <CtControlConfiguration>{
  name: "gradazione",
  label: "Gradazione alcolica",
  type: CtControlTypes.COUNTER,
  value: 5,
  validators: [
    { name: "required" }
  ],
  flexSpacePercentage: 33.33,
  flexSpaceBreakpoints: <Dictionary<number>>{
    xs: 100,
    sm: 100
  },
  theme: CtThemeTypes.MATERIAL
};
