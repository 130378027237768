import { IndirizzoModel } from "../indirizzo";
import { ClienteModel } from "../cliente";
import { CTBase } from "@ctsolution/ct-base";

export class IndirizzoSpedizioneModel extends CTBase<IndirizzoSpedizioneModel> {

  id: string | null = null;
  nomeDestinatario: string | null = null;
  telefonoDestinatario: string | null = null;
  emailDestinatario: string | null = null;
  isPrincipale: boolean = false;
  indirizzo: IndirizzoModel | null = null;
  cliente: ClienteModel | null = null;

  private constructor();
  private constructor(model?: IndirizzoSpedizioneModel);
  private constructor(model?: IndirizzoSpedizioneModel) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: IndirizzoSpedizioneModel): IndirizzoSpedizioneModel => new IndirizzoSpedizioneModel(model);

  setId(value: string | null) {

    this.id = value;
    return this;

  }

  setupByForm(value: { contatto: IndirizzoSpedizioneModel | null, indirizzo: IndirizzoModel, customer: ClienteModel }) {

    if (value) {

      if ((<any>value.customer)?.value) {

        value.customer = (<any>value.customer).value;

      }

      if ((<any>value.indirizzo.stato).value) {

        value.indirizzo.stato = (<any>value.indirizzo.stato).value;

      }

      this.getClass(value);

    }

    if (value.contatto) {

      this.getClass(value.contatto); // alcuni campi devono stare un livello sopra

    }

    if (this.indirizzo && !this.indirizzo?.StatoId) {

      this.indirizzo.StatoId = this.indirizzo?.stato?.id ?? null;

    }

    return this;

  }

  setNomeDestinatario(value: string | null) {

    this.nomeDestinatario = value;
    return this;

  }

  setTelefonoDestinatario(value: string | null) {

    this.telefonoDestinatario = value;
    return this;

  }

  setEmailDestinatario(value: string | null) {

    this.emailDestinatario = value;
    return this;

  }

  setIndirizzo(value: IndirizzoModel | null) {

    this.indirizzo = value;
    return this;

  }

}

