import {
  CtControlConfiguration,
  CtFormConfiguration,
  Dictionary
} from "@ctsolution/ct-framework";
import { CustomerControls } from "./customer.controls";

const BusinessCustomerControls: CtControlConfiguration[] = [
  <CtControlConfiguration>{
    name: "ragioneSociale",
    label: "CT_GENERAL.company_name",
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 60 }
    ]
  },
  <CtControlConfiguration>{
    name: "partitaIva",
    label: "CT_GENERAL.vat_number",
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 11 }
    ]
  }
];

export const BusinessCustomerData = (): CtFormConfiguration => <CtFormConfiguration>{
  controls: [...BusinessCustomerControls, ...CustomerControls]
};
