<mw-datatable
  #table
  class="addresses-datatable-container"
  *ngIf="listConfiguration"
  [configuration]="listConfiguration"
  (onSave)="onSave($event)">

  <ng-template #body let-value="value">

    <td *ngIf="!isClienteDetail">
      {{ value?.cliente?.nomeRiferimento ?? '' }}
    </td>

    <td>
      {{ value?.nomeDestinatario ?? '' }}
    </td>

    <td>
      {{ value?.indirizzo?.via ?? '' }}, {{ value?.indirizzo?.comune ?? '' }}, {{ value?.indirizzo?.cap ?? '' }}
      , {{ value?.indirizzo?.provincia ?? '' }}
    </td>

    <td>
      {{ value?.indirizzo?.stato?.nome ?? '' }}
    </td>

  </ng-template>

</mw-datatable>
