import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";

export const nomeDestinatarioSpedizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("destinatario.nome")
  .setLabel("Destinatario")
  .setInlineStyle("min-width: 400px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per destinatario")
      .setShowMenu(true));

export const emailDestinatarioSpedizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("destinatario.email")
  .setLabel("E-mail Destinatario")
  .setInlineStyle("min-width: 300px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per e-mail destinatario")
      .setShowMenu(true));

export const dataCreazioneSpedizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("dataCreazione")
  .setLabel("Data creazione")
  .setInlineStyle("min-width: 250px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per data creazione")
      .setType("date")
      .setShowMenu(true));


export const dataConsegnaSpedizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("dataConsegna")
  .setLabel("Data consegna prevista")
  .setInlineStyle("min-width: 300px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per data consegna")
      .setType("date")
      .setShowMenu(true));

export const indirizzoSpedizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("destinatario.indirizzo.via")
  .setLabel("Indirizzo Destinatario")
  .setSortable(true)
  .setInlineStyle("min-width: 350px;")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per indirizzo destinatario")
      .setShowMenu(true));

export const codiceSpedizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("codice")
  .setLabel("Codice")
  .setInlineStyle("min-width: 150px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per codice")
      .setShowMenu(true));

export const contenutoSpedizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("contenuto")
  .setLabel("Contenuto")
  .setInlineStyle("min-width: 300px;");
