import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerComponent } from "./customer.component";
import { RouterModule } from "@angular/router";
import { CtButtonModule, CtCardModule, CTDatatableModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MwDatatableModule } from "../../components/mw-datatable/mw-datatable.module";
import { MatDialogModule } from "@angular/material/dialog";
import { TableModule } from "primeng/table";
import { FormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { CustomerDatatableComponent } from "./customer-datatable/customer-datatable.component";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { MatIconModule } from "@angular/material/icon";
import { TabViewModule } from "primeng/tabview";
import { CustomerFormModule } from "./customer-form/customer-form.module";

@NgModule({
  declarations: [
    CustomerComponent,
    CustomerDatatableComponent
  ],
  imports: [
    CommonModule,
    CtButtonModule,
    FlexLayoutModule,
    CtCardModule,
    MatDialogModule,
    RouterModule.forChild([
      {
        path: "",
        component: CustomerComponent,
        data: {
          title: "Clienti",
          urls: []
        }
      }
    ]),
    MwDatatableModule,
    TableModule,
    InputTextModule,
    FormsModule,
    MatLegacyCardModule,
    CTDatatableModule,
    MatIconModule,
    TabViewModule,
    CustomerFormModule
  ]
})
export class CustomerModule {
}
