import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PrimeNGConfig } from "primeng/api";
import { JwtService } from "./core/lib/jwt.service";
import {
  CondizioniContrattualiService
} from "./pages/personal-area/profile/condizioni-contrattuali/condizioni-contrattuali.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {

  constructor(config: PrimeNGConfig, translate: TranslateService, private jwt: JwtService, private condizioniContrattualiService: CondizioniContrattualiService) {

    //translate.setDefaultLang("en");  spostato nell'app.module.ts
    translate.get("primeng").subscribe(res => config.setTranslation(res));

  }

  ngAfterViewInit() {

    this.checkIfUserAcceptedCommercialConditions();

  }

  async checkIfUserAcceptedCommercialConditions() {

    const commercialConditionsAccepted = await this.jwt.getCommercialConditionsAccepted();

    if (!commercialConditionsAccepted) {

      this.condizioniContrattualiService.openCondizioniContrattuali();

    }

  }

}
