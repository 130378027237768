import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";
import { TranslateService } from "@ngx-translate/core";

export const destinazioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("destinazione.nomeRiferimento")
  .setLabel("destinazione")
  .setInlineStyle("min-width: 150px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per destinazione")
      .setShowMenu(true));

export const nomeDestinatarioHeader= (translate: TranslateService): CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("nomeDestinatario")
  .setLabel(translate.instant("CT_DATATABLE.CLIENTRECORDS.Recipient"))
  .setInlineStyle("min-width: 200px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per destinatario")
      .setShowMenu(true));

export const indirizzoHeader= (translate: TranslateService): CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzo.via")
  .setLabel(translate.instant("CT_GENERAL.address"))
  .setSortable(true)
  .setInlineStyle("min-width: 300px;")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per indirizzo")
      .setShowMenu(true));

      export const statoHeader = (translate: TranslateService): CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzo.stato.nome")
  .setLabel(translate.instant('CT_DATATABLE.CLIENTRECORDS.State'))
  .setSortable(true)
  .setInlineStyle("min-width: 150px;")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant('CT_DATATABLE.CLIENTRECORDS.StateSearch'))
      .setShowMenu(true));
