import { ClienteModel } from "../../../core/classes/cliente";
import { TipoClienteEnum } from "../../../core/enum/tipo-cliente.enum";

export class CustomerFormConfiguration {

  values: ClienteModel | null = null;
  tipoCliente: TipoClienteEnum | null = null;

  private constructor() {
  }

  public static create = (): CustomerFormConfiguration => new CustomerFormConfiguration();

  setValues(value: ClienteModel | null) {

    this.values = value;
    return this;

  }

  setTipoCliente(value: TipoClienteEnum | null) {

    this.tipoCliente = value;
    return this;

  }

}
