import { CtControlConfiguration, CtControlTypes, CtThemeTypes, Dictionary } from "@ctsolution/ct-framework";

export const PRODUTTORE_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
  name: "produttore",
  label: "Produttore",
  type: CtControlTypes.TEXT,
  validators: [
    { name: "required" }
  ],
  flexSpacePercentage: 33.33,
  flexSpaceBreakpoints: <Dictionary<number>>{
    xs: 100,
    sm: 100
  },
  theme: CtThemeTypes.MATERIAL
};
