import { Component, Input, ViewChild } from "@angular/core";
import { MwDatatableComponent } from "../../../components/mw-datatable/mw-datatable.component";
import { IndirizzoSpedizioneModel } from "../../../core/classes/spedizione/indirizzo";
import { MwDatatableConfiguration } from "../../../components/mw-datatable/mw-datatable.configuration";
import { IndirizziSpedizioneApolloResult } from "../../../core/interfaces/apollo/indirizzi-spedizione.apollo-result";
import { ApolloVariableClass } from "../../../core/classes/apollo/apollo.variable";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";
import {
  CTDatatableButtonConfiguration,
  CTDatatableConfiguration,
  CTDatatablePermissions
} from "@ctsolution/ct-framework";
import { QueriesService } from "../../../core/lib/queries.service";
import { BaseController } from "../../../core/controllers/base.controller";
import { ShipmentAddressFormService } from "../../../components/shipment-address-form/shipment-address-form.service";
import { clienteHeader, indirizzoHeader, nomeDestinatarioHeader, statoHeader } from "./addresses-datatable.headers";
import { AddressesDatatableConfiguration } from "./addresses-datatable.configuration";

@Component({
  selector: "app-addresses-datatable",
  templateUrl: "./addresses-datatable.component.html",
  styles: [`::ng-deep .addresses-datatable-container {
    .mw-datatable-cntr {
      min-width: 745px
    }

    @media screen and (max-width: 883px) {
      .mw-datatable-cntr {
        min-width: auto
      }
    }
  }`
  ]
})
export class AddressesDatatableComponent {

  @ViewChild("table") table: MwDatatableComponent<IndirizzoSpedizioneModel | null> | null = null;
  @Input() configuration: AddressesDatatableConfiguration | null = null;

  listConfiguration: MwDatatableConfiguration<IndirizziSpedizioneApolloResult> | null = MwDatatableConfiguration
    .create<IndirizziSpedizioneApolloResult>()
    .setFetchDataCaller(async (variables: ApolloVariableClass) => {

      let query = await this._queries.entities.indirizziSpedizione();

      if (!query) return null;

      const parameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(query);

      if (this.configuration?.cliente) {

        const indexCliente = variables.whereAndFilters?.findIndex(item => item.cliente);

        if (indexCliente) {

          const clienteFilter = {
            cliente: {
              id: {
                eq: this.configuration.cliente.id
              }
            }
          };

          if (indexCliente !== -1) {
            // Modifica il campo desiderato se l'oggetto "cliente" è presente
            (variables.whereAndFilters ?? [])[indexCliente] = clienteFilter;

          } else {

            // Aggiungi un nuovo oggetto "cliente" all'array se non presente
            (variables.whereAndFilters ?? []).push(clienteFilter);

          }

        }

      }

      return this.baseController.list<IndirizziSpedizioneApolloResult>(parameter);

    })
    .setFetchResponseDataMapper((value: IndirizziSpedizioneApolloResult) => value.indirizziSpedizione)
    .setCTDatatableConfiguration(
      CTDatatableConfiguration
        .create<IndirizziSpedizioneApolloResult>()
        .setEmptyMessage("Nessun indirizzo disponibile")
        .setLoadingBody("Caricamento indirizzi. Attendere prego.")
        .setPermissions([CTDatatablePermissions.insert, CTDatatablePermissions.update])
        .setCreateButtonConfiguration(
          CTDatatableButtonConfiguration
            .create()
            .setLabel("Nuovo indirizzo"))
        .setSortField("cliente.nomeRiferimento")
        .setSortOrder(-1)
    );

  constructor(
    private _queries: QueriesService,
    private baseController: BaseController,
    private shipmentAddressFormService: ShipmentAddressFormService) {
  }

  get isClienteDetail() {

    return !!this.configuration?.cliente;

  }

  ngAfterViewInit() {

    const columns = [
      nomeDestinatarioHeader,
      indirizzoHeader,
      statoHeader
    ];

    if (!this.isClienteDetail) {

      columns
        .unshift(clienteHeader);

    }

    this.listConfiguration
      ?.CTDatatableConfiguration
      ?.setHeaders(columns);

  }

  async onSave(event: IndirizzoSpedizioneModel | null) {

    if (this.configuration?.cliente && !event?.cliente) {

      if (!event) event = {} as IndirizzoSpedizioneModel;

      event["cliente"] = this.configuration.cliente;

    }

    this.shipmentAddressFormService
      .setupShipmentAddress(event)
      .then(success => {

        if (success) {

          this.table
            ?.reinit();

        }

      });

  }

}
