import { Component } from "@angular/core";
import { DepositorService } from "../../../core/lib/depositor.service";
import { QueriesService } from "../../../core/lib/queries.service";
import { BaseController } from "../../../core/controllers/base.controller";
import { DestinazioneModel } from "../../../core/classes/destinazione";
import { DepositanteApolloResult } from "../../../core/interfaces/apollo/depositante.apollo-result";
import { CondizioniContrattualiService } from "./condizioni-contrattuali/condizioni-contrattuali.service";
import { environment } from "../../../../environments/environment";
import { AddressUpdateDialogService } from "./address-update-dialog/address-update-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { ProfileUpdateDialogService } from "./profile-update-dialog/profile-update-dialog.service";
import { JwtService } from "src/app/core/lib/jwt.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent {

  model: DestinazioneModel | null = null;
  showData : boolean = true;

  constructor(
    private depositorService: DepositorService,
    private baseController: BaseController,
    private _queries: QueriesService,
    private addressUpdateDialogService: AddressUpdateDialogService,
    private condizioniContrattualiService: CondizioniContrattualiService,
    private translateService: TranslateService,
    private titleService: Title,
    private profileUpdateService : ProfileUpdateDialogService,
    private _jwt : JwtService
  ) {
  }

  async ngOnInit() {

    this.translateService.get('CT_MENU.profilo').subscribe((translation: string) => {
      this.titleService.setTitle(translation);
    });

    const fetcher = await this.getDepositorData();

    fetcher
      ?.subscribe(response => this.model = response.depositante);

    const depositors: Array<string> | null = await this._jwt.getDepositanti();
     if(depositors == null)
     {
      this.updateAddress()
      this.showData = false;
     }

  }

  async getDepositorData() {

    const depositanteId = await this.depositorService.getDepositor();

    const query = await this._queries.entities.depositante();

    if (!query) return null;

    return await this.baseController.get<DepositanteApolloResult>(depositanteId, query);

  }

  openCondizioniContrattuali() {

    this.condizioniContrattualiService.openCondizioniContrattuali();

  }

  updatePassword() {

    window.location.href = `${environment.keycloak.config.url}realms/mostwine/protocol/openid-connect/auth?client_id=${environment.keycloak.config.clientId}&redirect_uri=${window.location.origin}&response_type=code&kc_action=UPDATE_PASSWORD`;

  }

  updateAddress() {

    this.profileUpdateService.open(this.model ?? null);

  }

}
