import { Component } from "@angular/core";
import { DepositorService } from "../../../core/lib/depositor.service";
import { QueriesService } from "../../../core/lib/queries.service";
import { BaseController } from "../../../core/controllers/base.controller";
import { ClienteModel } from "../../../core/classes/cliente";
import { DepositanteApolloResult } from "../../../core/interfaces/apollo/depositante.apollo-result";
import { CondizioniContrattualiService } from "./condizioni-contrattuali/condizioni-contrattuali.service";
import { environment } from "../../../../environments/environment";
import { AddressUpdateDialogService } from "./address-update-dialog/address-update-dialog.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent {

  model: ClienteModel | null = null;

  constructor(
    private depositorService: DepositorService,
    private baseController: BaseController,
    private _queries: QueriesService,
    private addressUpdateDialogService: AddressUpdateDialogService,
    private condizioniContrattualiService: CondizioniContrattualiService) {
  }

  async ngOnInit() {

    const fetcher = await this.getDepositorData();

    fetcher
      ?.subscribe(response => this.model = response.depositante);

  }

  async getDepositorData() {

    const depositanteId = await this.depositorService.getDepositor();

    const query = await this._queries.entities.depositante();

    if (!query) return null;

    return await this.baseController.get<DepositanteApolloResult>(depositanteId, query);

  }

  openCondizioniContrattuali() {

    this.condizioniContrattualiService.openCondizioniContrattuali();

  }

  updatePassword() {

    window.location.href = `${environment.keycloak.config.url}realms/mostwine/protocol/openid-connect/auth?client_id=${environment.keycloak.config.clientId}&redirect_uri=${window.location.origin}&response_type=code&kc_action=UPDATE_PASSWORD`;

  }

  updateAddress() {

    this.addressUpdateDialogService.open(this.model ?? null);

  }

}
