import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { ModelDetailConfiguration } from "./model-detail.configuration";
import { ModelDetailComponent } from "./model-detail.component";
import { DetailDataConfiguration } from "./section-detail-data/detail-data.configuration";
import { SectionDetailDataConfiguration } from "./section-detail-data/section-detail-data.configuration";
import { ProdottiModel } from "../../core/classes/prodotti";
import { CtCardIconConfiguration, CtCardIconPosition } from "@ctsolution/ct-framework";
import { GiacenzaVinoModel } from "../../core/interfaces/giacenza-vino.model";
import { GiacenzaPalletModel } from "../../core/classes/deposito/giacenza";
import {
  defaultBoxBottleCount
} from "../../pages/deposit/deposit-create-wizard/deposit-create-step2/box-composer/box-composer.component";

@Injectable({
  providedIn: "root"
})
export class ModelDetailService {

  constructor(private dialog: MatLegacyDialog) {
  }

  open = (data: ModelDetailConfiguration, params?: {
    cssClass?: string, minWidth?: number | string, width?: number | string, maxWidth?: number | string
  }) => this.dialog.open(ModelDetailComponent, {
    data,
    panelClass: [params?.cssClass || ""],
    minWidth: <string | undefined>params?.minWidth,
    width: <string | undefined>params?.width,
    maxWidth: <string | undefined>params?.maxWidth
  });

  getProdottoSection(model: ProdottiModel) {

    const prodottiCardConfiguration = (title: string, description: string, icon: string | null = null): DetailDataConfiguration => DetailDataConfiguration
      .create()
      .setCTCardConfigurationFromDetailDataModel(title, description, icon);

    let quantitaScatoleDetailData: DetailDataConfiguration | null = null,
      quantitaBottiglieDetailData: DetailDataConfiguration | null = null,
      descrizioneDetailData: DetailDataConfiguration | null = null;

    if (model.quantitaScatole) {

      quantitaScatoleDetailData = prodottiCardConfiguration("Nr. Scatole", model.quantitaScatole.toString()) //, iconsBasePath + "box.svg"
        .setFxLayout({
          sm: 100,
          md: 30,
          lg: 30
        });

      quantitaScatoleDetailData
        .cardConfiguration
        ?.setIconSetting(
          CtCardIconConfiguration
            .create()
            .setIcon("arrow_forward_ios")
            .setPosition(CtCardIconPosition.SUFFIX)
        );

    }

    if (model.quantitaBottiglie) {

      quantitaBottiglieDetailData = prodottiCardConfiguration("Nr. Bottiglie", model.quantitaBottiglie.toString()) // , iconsBasePath + "bottle.svg"
        .setFxLayout({
          sm: 100,
          md: 30,
          lg: 30
        });

      quantitaBottiglieDetailData
        .cardConfiguration
        ?.setIconSetting(
          CtCardIconConfiguration
            .create()
            .setIcon("arrow_forward_ios")
            .setPosition(CtCardIconPosition.SUFFIX)
        );

    }

    if (model?.prodotto?.descrizione) {

      const responsiveFlex = (quantitaScatoleDetailData && quantitaBottiglieDetailData) ? 40 : (quantitaScatoleDetailData || quantitaBottiglieDetailData) ? 70 : 100;

      descrizioneDetailData = prodottiCardConfiguration("Vino", model?.prodotto?.descrizione) // , iconsBasePath + "grape.svg"
        .setFxLayout({
          sm: 100,
          md: responsiveFlex,
          lg: responsiveFlex
        });

    }

    const detailDataConfigurations: DetailDataConfiguration[] = [quantitaScatoleDetailData, quantitaBottiglieDetailData, descrizioneDetailData].filter(elm => !!elm) as DetailDataConfiguration[];

    const fxFlex = Math.round(100 / detailDataConfigurations.filter(elm => !!elm).length);

    detailDataConfigurations
      .forEach(configuration => configuration.setFxFlex(fxFlex));

    const toReturn = SectionDetailDataConfiguration
      .create()
      .setDataList(detailDataConfigurations);

    if (model.prodotto?.id) {

      toReturn
        .addAttribute("prodottoId", model.prodotto.id);

    }

    return toReturn;

  }

  getProdottoSectionFromGiacenzaVinoModel(giacenza: GiacenzaVinoModel) {

    const model = {
      prodotto: giacenza.vino,
      quantitaScatole: giacenza.quantitaScatole,
      quantitaBottiglie: giacenza.quantita // se scatola abbiamo 6 come default
    } as ProdottiModel;

    return this
      .getProdottoSection(model)
      .enableEndSectionDivider(true);

  }

  getGiacenzaPalletSectionsFromGiacenzaPalletModel(value: GiacenzaPalletModel, quantity: number | null = null) {

    const section: SectionDetailDataConfiguration = SectionDetailDataConfiguration
      .create((quantity ? quantity + " x " : "") + "Pallet")
      .addAttribute("maxQuantity", value.quantita)
      .addAttribute("giacenzaPalletId", value.id);

    const sections: SectionDetailDataConfiguration[] = [section];

    value
      .contenuti
      ?.forEach(contenuto => {

        const prodottoSection = this.getProdottoSectionFromGiacenzaVinoModel(contenuto);

        sections
          .push(prodottoSection);

      });

    return sections;

  }

  getGiacenzaScatolaSectionsFromGiacenzaVinoModel(value: GiacenzaVinoModel, quantity: number | null = null) {

    const section: SectionDetailDataConfiguration = SectionDetailDataConfiguration
      .create((quantity ? quantity + " x " : "") + "Scatola")
      .addAttribute("prodottoId", value.vino.id)
      .addAttribute("quantitaTotaleBottiglie", value.quantita);

    const sections: SectionDetailDataConfiguration[] = [section];

    const giacenzaVinoInScatola = Object.assign({}, value);
    giacenzaVinoInScatola.quantitaScatole = 0;
    giacenzaVinoInScatola.quantita = defaultBoxBottleCount;

    const prodottoSection = this.getProdottoSectionFromGiacenzaVinoModel(giacenzaVinoInScatola);

    sections
      .push(prodottoSection);

    return sections;

  }

  findSectionByDescription(sections: SectionDetailDataConfiguration[], description: string) {
    return sections.find(section => section.dataList.find(elm =>
      (<string | null>elm.cardConfiguration?.description)?.toLowerCase() === description?.toLowerCase()));
  }

  findDetailDataByTitle(section: SectionDetailDataConfiguration, title: string) {
    return section.dataList.find(data =>
      data.cardConfiguration?.title?.toLowerCase()?.includes(title));
  }

}
