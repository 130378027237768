import { TipoClienteEnum } from "../../../core/enum/tipo-cliente.enum";

export class CustomerDatatableConfiguration {

  get customerType(): TipoClienteEnum | null {
    return this._customerType;
  }

  private set customerType(value: TipoClienteEnum | null) {
    this._customerType = value;
  }

  private _customerType: TipoClienteEnum | null = null;

  public static create = (): CustomerDatatableConfiguration => new CustomerDatatableConfiguration();

  setCustomerType(value: TipoClienteEnum | null): CustomerDatatableConfiguration {

    this.customerType = value;
    return this;

  }

  get isBusiness(): boolean {

    return this.customerType === TipoClienteEnum.ClienteBusiness;

  }

}
