import { Injectable } from "@angular/core";
import { BaseController } from "../../core/controllers/base.controller";
import { ModelDetailConfiguration } from "../../components/model-detail/model-detail.configuration";
import {
  SectionDetailDataConfiguration
} from "../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { ModelDetailService } from "../../components/model-detail/model-detail.service";
import { QueriesService } from "../../core/lib/queries.service";
import { ProdottoModel } from "../../core/classes/prodotto";
import { VinoApolloResult } from "../../core/interfaces/apollo/vino.apollo-result";
import { iconsBasePath } from "../../core/constants";
import { WineCreationFormConfiguration } from "./wine-creation-form/wine-creation-form.configuration";
import { WineCreationFormComponent } from "./wine-creation-form/wine-creation-form.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { DetailDataConfiguration } from "../../components/model-detail/section-detail-data/detail-data.configuration";

const GRAPE_ICON_PATH = `${iconsBasePath}grape.svg`;

@Injectable()
export class ProductService {

  constructor(
    private dialog: MatLegacyDialog,
    private baseController: BaseController,
    private detailDataService: ModelDetailService,
    private _queries: QueriesService
  ) {
  }

  private openFormDialog(value: ProdottoModel | null, params?: {
    width?: number | string;
    maxWidth?: number | string;
  }) {

    const data: WineCreationFormConfiguration = WineCreationFormConfiguration
      .create()
      .setValues(value);

    return this.dialog
      .open(WineCreationFormComponent, {
        data, panelClass: "entity-form-dialog",
        disableClose: true,
        width: <string | undefined>params?.width,
        maxWidth: <string | undefined>params?.maxWidth
      });

  }

  async setupProduct(dataSource: ProdottoModel | null = null): Promise<boolean | null | undefined> {

    if (dataSource) {

      dataSource = await this.getProductData(dataSource.id);

      if (!dataSource) return null;

      dataSource = ProdottoModel.create(dataSource);

    }

    return this.createOrUpdateByEntityForm(dataSource);

  }

  async createOrUpdateByEntityForm(dataSource: ProdottoModel | null): Promise<boolean | null | undefined> {

    return new Promise<boolean | null | undefined>((resolve) => {

      this.openFormDialog(dataSource, { maxWidth: "840px" })
        .afterClosed()
        .subscribe(async (result: boolean | null | undefined) => resolve(result));

    });

  }

  async getProductData(productId: string | null): Promise<ProdottoModel | null> {

    if (!productId) return null;

    const query = await this._queries.entities.vino();

    if (!query) return null;

    const caller = await this.baseController.get<VinoApolloResult>(productId, query);

    if (!caller) return null;

    try {

      const result = await new Promise<VinoApolloResult>((resolve, reject) => {

        caller
          .subscribe({
            next: (data) => resolve(data),
            error: (error) => reject(error)
          });

      });

      return result?.vino ?? null;

    } catch (error) {

      // Gestire eventuali errori qui
      console.error(error);
      return null;

    }

  }

  async getProductDialogDetail(productId: string) {

    const model = await this.getProductDataModelDetailDialog(productId);

    if (!model) return;

    this.detailDataService.open(model, { maxWidth: "600px" });

  }

  private async getProductDataModelDetailDialog(productId: string | null): Promise<ModelDetailConfiguration | null> {

    const model = await this.getProductData(productId);

    if (!model) return null;

    const modelDetail: ModelDetailConfiguration = ModelDetailConfiguration
      .create()
      .setTitle("Dettaglio vino");

    const productDetailSection = this.getProductDetailSection(model);

    modelDetail
      .addSection(productDetailSection);

    return modelDetail;

  }

  private getProductDetailSection(value: ProdottoModel) {

    const section: SectionDetailDataConfiguration = SectionDetailDataConfiguration.create();

    if (value.descrizione) {

      const descriptionDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("Descrizione", value.descrizione, GRAPE_ICON_PATH)
        .setFxLayout(null);

      section
        .addDetailData(descriptionDetailData);

    }

    if (value.denominazione?.nome) {

      const denominazioneDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.denominazione", value.denominazione?.nome);

      section
        .addDetailData(denominazioneDetailData);

    }

    if (value.menzione?.nome) {

      const menzioneDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.menzione", value.menzione?.nome);

      section
        .addDetailData(menzioneDetailData);

    }

    if (value.formato?.nome) {

      const formatoDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.format_buttle", value.formato?.nome);

      section
        .addDetailData(formatoDetailData);

    }

    if (value.tipoVino?.nome) {

      const tipoVinoDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.wine_type", value.tipoVino?.nome);

      section
        .addDetailData(tipoVinoDetailData);

    }

    if (value.tipologia?.nome) {

      const tipologiaDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.sub_family", value.tipologia?.nome);

      section
        .addDetailData(tipologiaDetailData);

    }

    if (value.produttore) {

      const produttoreDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_TAG.Produttore", value.produttore);

      section
        .addDetailData(produttoreDetailData);

    }

    if (value.anno) {

      const annoDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.production_year", value.anno);

      section
        .addDetailData(annoDetailData);

    }

    const flagAlcolDetailData = DetailDataConfiguration
      .create()
      .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.alcohol", String(value?.flagAlcol ? "✔" : "〤"));

    section
      .addDetailData(flagAlcolDetailData);

    if (value.gradazione) {

      const gradazioneDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.alcohol_content", value.gradazione);

      section
        .addDetailData(gradazioneDetailData);

    }

    if (value.stato) {

      const statoDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.location", value.stato?.nome);

      section
        .addDetailData(statoDetailData);

    }

    if (value.metodoSpumante) {

      const metodoSpumanteData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("Metodologia", value.metodoSpumante.nome);

      section
        .addDetailData(metodoSpumanteData);

    }

    if (value.dosaggioSpumante) {

      const dosaggioSpumanteData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("Dosaggio", value.dosaggioSpumante.nome);

      section
        .addDetailData(dosaggioSpumanteData);

    }

    if (value.annoSpumante) {

      const annoSpumanteData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("Anno", value.annoSpumante.nome);

      section
        .addDetailData(annoSpumanteData);

    }


    return section;

  }

}
