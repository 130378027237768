import { Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CustomerModule } from "./customer/customer.module";
import { ProductModule } from "./product/product.module";
import { ShipmentModule } from "./shipment/shipment.module";
import { DepositModule } from "./deposit/deposit.module";
import { ProfileModule } from "./personal-area/profile/profile.module";
import { MovementsModule } from "./movements/movements.module";
import { ProfileGuard } from "../core/guard/profileGuard";

export const PagesRoutes: Routes = [
  {
    path: "",
    canActivate: [ProfileGuard],
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        data: {
          title: "CT_MENU.your_dashboard",
          urls: []
        }
      },
      {
        path: "vini",
        loadChildren: () => ProductModule
      },
      {
        path: "clienti",
        loadChildren: () => CustomerModule
      },
      {
        path: "spedizioni",
        loadChildren: () => ShipmentModule
      },
       {
         path: "depositi",
         loadChildren: () => DepositModule
       },
      {
        path: "movimentazioni",
        loadChildren: () => MovementsModule
      },
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full"
      }
    ]
  },
  {
    path: "profilo",
    loadChildren: () => ProfileModule
  },
];
