import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";

export const codiceHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("codice")
  .setLabel("Codice")
  .setInlineStyle("width: 240px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per codice")
      .setShowMenu(true));

export const descrizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("descrizione")
  .setLabel("Descrizione")
  .setInlineStyle("min-width: 350px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per descrizione")
      .setShowMenu(true));

export const dataPrevistaHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setLabel("Dettagli di Consegna");

export const statoHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("stato")
  .setInlineStyle("width: 100px;")
  .setLabel("Stato");
