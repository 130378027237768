import { BaseRequestClass } from "../classes/apollo/apollo.base.request";

const DEPOSITANTI_CONTROLLER: string[] = ["depositanti"];

export const DepositantiControllerRequest = (): BaseRequestClass => BaseRequestClass
  .create()
  .setController(DEPOSITANTI_CONTROLLER);

const DEPOSITI_CONTROLLER: string[] = ["depositi"];

export const DepositiControllerRequest = (): BaseRequestClass => BaseRequestClass
  .create()
  .setController(DEPOSITI_CONTROLLER);

const SHIPMENT_CONTROLLER: string[] = ["spedizioni"];

export const ShipmentControllerRequest = (): BaseRequestClass => BaseRequestClass
  .create()
  .setController(SHIPMENT_CONTROLLER);

const SHIPMENT_ADDRESS_CONTROLLER: string[] = ["indirizzi-spedizione"];

export const ShipmentAddressControllerRequest = (): BaseRequestClass => BaseRequestClass
  .create()
  .setController(SHIPMENT_ADDRESS_CONTROLLER);

const PRODUCT_CONTROLLER: string[] = ["prodotti"];

export const ProductControllerRequest = (): BaseRequestClass => BaseRequestClass
  .create()
  .setController(PRODUCT_CONTROLLER);

const CUSTOMER_CONTROLLER: string[] = ["clienti"];

export const CustomerControllerRequest = (): BaseRequestClass => BaseRequestClass
  .create()
  .setController(CUSTOMER_CONTROLLER);
