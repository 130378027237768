import { CtControlConfiguration, CtControlTypes, Dictionary } from "@ctsolution/ct-framework";

export const CustomerControls: CtControlConfiguration[] = [
  <CtControlConfiguration>{
    name: "codiceFiscale",
    label: "CT_GENERAL.fiscal_code",
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 16 }
    ]
  },
  <CtControlConfiguration>{
    name: "email",
    label: "CT_GENERAL.email",
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "email" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 128 }
    ],
    type: CtControlTypes.EMAIL
  },
  <CtControlConfiguration>{
    name: "telefono",
    label: "CT_GENERAL.phone_number",
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 40 }
    ],
    type: CtControlTypes.TEL
  }
];

