import { CtControlConfiguration, CtControlTypes, CtThemeTypes, Dictionary } from "@ctsolution/ct-framework";

export const NOME_COMMERCIALE_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
  name: "nomeCommerciale",
  label: "Nome Commerciale",
  type: CtControlTypes.TEXT,
  flexSpacePercentage: 33.33,
  flexSpaceBreakpoints: <Dictionary<number>>{
    xs: 100,
    sm: 100
  },
  theme: CtThemeTypes.MATERIAL
};
