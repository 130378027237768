import {
  CTDatatableFilterConfiguration,
  CTDatatableHeaderCellConfiguration,
  CTDatatatableHeaderCellIconConfiguration,
  CTDatatatableHeaderCellIconType
} from "@ctsolution/ct-framework";
import { iconsBasePath } from "../../core/constants";

export const clienteHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("depositante.nomeRiferimento")
  .setLabel("Cliente")
  .setInlineStyle("min-width: 200px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per nome di riferimento depositante")
      .setShowMenu(true));

export const depositoCodiceHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("deposito.codice")
  .setLabel("Deposito")
  .setInlineStyle("min-width: 100px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per codice deposito")
      .setShowMenu(true));

export const quantitaHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantita")
  .setLabel("Quantità")
  .setInlineStyle("width: 300px;")
  .setSortable(true)
  .setIcon(
    CTDatatatableHeaderCellIconConfiguration
      .create()
      .setIcon(`${iconsBasePath}pallet.svg`)
      .setType(CTDatatatableHeaderCellIconType.image)
  )
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setType("numeric")
      .setPlaceHolder("Cerca per quantità")
      .setShowMenu(true));

export const quantitaInLavorazioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantitaInLavorazioneHeader")
  .setLabel("In lavorazione")
  .setInlineStyle("width: 250px;")
  .setSortable(true)
  .setIcon(
    CTDatatatableHeaderCellIconConfiguration
      .create()
      .setIcon(`${iconsBasePath}pallet-on-work.svg`)
      .setType(CTDatatatableHeaderCellIconType.image)
  )
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setType("numeric")
      .setPlaceHolder("Cerca per quantità in lavorazione")
      .setShowMenu(true));

export const vinoDescrizioneHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("vino.descrizione")
  .setLabel("Vino")
  .setSortable(true)
  .setInlineStyle("min-width:450px")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per descrizione vino")
      .setShowMenu(true));

export const quantitaTotaleHeader: () => CTDatatableHeaderCellConfiguration = () => CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantita")
  .setLabel("Totale bottiglie")
  .setInlineStyle("width: 300px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca quantità bottiglie")
      .setType('numeric')
      .setShowMenu(true));

export const quantitaScatoleHeader: () => CTDatatableHeaderCellConfiguration = () => CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantitaScatole")
  .setLabel("Scatole")
  .setInlineStyle("width: 250px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca quantità scatole")
      .setType('numeric')
      .setShowMenu(true));

export const quantitaBottiglieSfuseHeader: () => CTDatatableHeaderCellConfiguration = () => CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantitaBottiglieSfuse")
  .setLabel("Sfuse")
  .setInlineStyle("width: 200px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca quantità bottiglie")
      .setType('numeric')
      .setShowMenu(true));

export const contenutoHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("contenuti.vino.descrizioneBreve")
  .setLabel("Contenuto")
  .setInlineStyle("min-width: 500px;")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per contenuto")
      .setShowMenu(true));

export const vinoProduttoreHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("vino.produttore")
  .setLabel("Produttore")
  .setInlineStyle("min-width: 180px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per produttore")
      .setShowMenu(true));
