import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { QueriesService } from "../../../core/lib/queries.service";
import { ClienteModel } from "../../../core/classes/cliente";
import { ClientiApolloResult } from "../../../core/interfaces/apollo/clienti.apollo-result";
import { AbstractControl, FormControl } from "@angular/forms";
import { MwLookupControlConfiguration } from "../../mw-lookup-control/mw-lookup-control.configuration";
import { CtControlTypes } from "@ctsolution/ct-framework";

@Component({
  selector: "app-customer-lookup-control",
  template: `
    <mw-lookup-control [configuration]="configuration"></mw-lookup-control>`
})
export class CustomerLookupControlComponent {

  @Input() control: FormControl | AbstractControl | any | null = null;

  configuration: MwLookupControlConfiguration<ClienteModel, ClientiApolloResult> = MwLookupControlConfiguration
    .create<ClienteModel, ClientiApolloResult>()
    .setResponseMapper(value => value.clienti.nodes)
    .setQuery(this._queries.entities.clienti)
    .setOptionDescriptionFn(value => value?.nomeRiferimento ?? "")
    .setOrder([{ nomeRiferimento: "ASC" }])
    .configureControl(control => {

      control
        ?.setLabel("CT_GENERAL.item-customer")
        ?.setType(CtControlTypes.LOOKUP);

    });

  constructor(private cdr: ChangeDetectorRef, private _queries: QueriesService) {
  }

  ngAfterViewInit() {

    if (this.control) {

      this.configuration
        .CTControl
        ?.setControl(this.control);

    }

    this.cdr.detectChanges();

  }

}
