import { CTBase } from "@ctsolution/ct-base";
import { MWDateFormatter } from "../lib/date-format.service";
import { TipodestinazioneEnum } from "../enum/tipo-destinazione.enum";
import { GenericReadModel } from "./generic-read.model";
import { IndirizzoModel } from "./indirizzo";

export class DestinazioneModel extends CTBase<DestinazioneModel> {

  id: string | null = null;
  tipo: TipodestinazioneEnum | null = null;
  telefono: string | null = null;
  email: string | null = null;
  codiceFiscale: string | null = null;
  ragioneSociale: string | null = null;
  partitaIva: string | null = null;
  nome: string | null = null;
  cognome: string | null = null;
  dataDiNascita: string | null = null;
  nomeRiferimento: string | null = null;
  codiceRiferimento: string | null = null;
  depositante: GenericReadModel | null = null;
  indirizzo: IndirizzoModel | null = null;
  value: any;

  private constructor();
  private constructor(model?: DestinazioneModel);
  private constructor(model?: DestinazioneModel) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: DestinazioneModel) => new DestinazioneModel(model);

  setId(value: string | null) {

    this.id = value;
    return this;

  }

  setDataDiNascita(value: string | Date | null) {

    if (value instanceof Date) {

      value = MWDateFormatter
        .create()
        .getDateISOString(value);

    }

    this.dataDiNascita = value;
    return this;

  }

  setTipodestinazione(value: TipodestinazioneEnum | null) {

    this.tipo = value;
    return this;

  }

  setIndirizzo(value: IndirizzoModel | null) {

    this.indirizzo = value;
    this.indirizzo = this.indirizzo;

    if (value) {

      if ((<any>value?.stato)?.value) {

        value.stato = (<any>value?.stato)?.value;

      }

      if (value?.stato?.id) {

        (<any>this.indirizzo)["StatoId"] = value?.stato?.id ?? null;

      }

    }

    return this;

  }

  setContact(value : { telefono: string | null, email: string | null, nome : string | null }) {

    this.nome = value.nome;
    this.telefono = value.telefono;
    this.email = value.email;

    return this;
  }

}
