<form
  [formGroup]="form">

  <div
    formArrayName="wines"
    *ngFor="let wine of wines.controls; let i = index; let first = first;"
    class="box-container m-b-5"
    [class]="'type-'+ configuration?.depositPackagingType">

    <hr *ngIf="!first" />

    <form
      class="m-b-5"
      fxLayout="row wrap"
      [formGroup]="wine">

      <div
        fxFlex="15"
        fxFlex.gt-md="5"
        *ngIf="moreThanOneWine && isPalletComposer">

        <button mat-icon-button style="margin-top: 5px" class="mw-icon-button remove" (click)="removeWine(i)">
          <img
            height="35" [src]="'/assets/images/icons/delete.svg'">
        </button>

      </div>

      <div
        fxFlex="15"
        fxFlex.gt-md="5"
        *ngIf="!isPalletComposer"
        class="center-items">

        <img height="35" [matTooltip]="packageTypeName()" [src]="packageTypeIcon()">

      </div>

      <div
        [fxFlex]="moreThanOneWine || !isPalletComposer ? 85 : 100"
        [fxFlex.gt-md]="moreThanOneWine  || !isPalletComposer ? 45  : 50">

        <app-product-lookup-control
          #productLookupControls
          [control]="wine.get('prodotto')"></app-product-lookup-control>

      </div>

      <div
        [hidden]="isBottiglieSfuseComposer"
        fxFlex="100"
        [fxFlex.gt-md]="isScatolaComposer ? 22.5 : 16.666">

        <app-qta-control
          [configuration]="qtaControlService.getControlConfiguration(wine.get('quantitaScatole'), 'CT_GENERAL.packs-count', !this.isBottiglieSfuseComposer ? null : [])"></app-qta-control>

      </div>

      <div
        fxFlex="100"
        [fxFlex.gt-md]="isBottiglieSfuseComposer ? 45 : isScatolaComposer ? 22.5 : 16.666">

        <app-qta-control
          [configuration]="qtaControlService.getControlConfiguration(wine.get('quantitaBottiglie'), ('CT_GENERAL.' + ( this.isBottiglieSfuseComposer ? 'bottle-count':'box-bottle-count')))"></app-qta-control>

      </div>

      <div
        [hidden]="isBottiglieSfuseComposer || isScatolaComposer"
        fxFlex="100"
        [fxFlex.gt-md]="16.666">

        <app-qta-control
          [configuration]="qtaControlService.getControlConfiguration(wine.get('quantitaTotale'), 'bottle-total')"></app-qta-control>

      </div>

      <div
        fxFlex="15"
        fxFlex.gt-md="5"
        class="center-items"
        style="padding-top: 0px !important"
        *ngIf="!isPalletComposer">

        <button mat-icon-button class="mw-icon-button remove" (click)="removePackage(i)">
          <img height="35" [src]="'/assets/images/icons/delete.svg'">
        </button>

      </div>

    </form>

  </div>

</form>

