import { Component, ViewChild } from "@angular/core";
import { QueriesService } from "../../../../../core/lib/queries.service";
import { GenericReadModel } from "../../../../../core/classes/generic-read.model";
import { MenzioniApolloResult } from "../../../../../core/interfaces/apollo/menzioni.apollo-result";
import { MwLookupControlComponent } from "../../../../../components/mw-lookup-control/mw-lookup-control.component";
import {
  MwLookupControlConfiguration
} from "../../../../../components/mw-lookup-control/mw-lookup-control.configuration";

@Component({
  selector: "mw-menzione-control",
  template: `
    <mw-lookup-control
      #lookupControl
      [configuration]="configuration"></mw-lookup-control>`
})
export class MenzioneControlComponent {

  @ViewChild("lookupControl") lookupControl: MwLookupControlComponent<GenericReadModel, MenzioniApolloResult> | null = null;

  configuration: MwLookupControlConfiguration<GenericReadModel, MenzioniApolloResult> = MwLookupControlConfiguration
    .create<GenericReadModel, MenzioniApolloResult>()
    .setQuery(this._queries.entities.menzioni)
    .setResponseMapper(value => value.menzioni)
    .setOptionDescriptionFn(value => value?.nome ?? "Unknown")
    .setAutoInitializeValueOptions(false)
    .setOrder([{ nome: "ASC" }])
    .configureControl(control => {

      control
        ?.setLabel("Menzione");

    });

  constructor(private _queries: QueriesService) {
  }

}
