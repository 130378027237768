import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StocksCounterWidgetComponent } from "./stocks-counter-widget.component";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { FlexLayoutModule } from "@angular/flex-layout";


@NgModule({
  declarations: [
    StocksCounterWidgetComponent
  ],
  exports: [
    StocksCounterWidgetComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyCardModule
  ]
})
export class StocksCounterWidgetModule {
}
