import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { DatePipe, registerLocaleData } from "@angular/common";
import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FullModule } from "./layouts/full/full.module";
import { CtFrameworkModule } from "@ctsolution/ct-framework";
import { CtWebapiModule, CtWebapiSetup } from "@ctsolution/ct-webapi";
import { environment } from "../environments/environment";
import { SpinnerModule } from "./layouts/spinner/spinner.module";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { APOLLO_OPTIONS, ApolloModule } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import {
  CondizioniContrattualiModule
} from "./pages/personal-area/profile/condizioni-contrattuali/condizioni-contrattuali.module";
import { ConfirmModule } from "./components/confirm/confirm.module";
import localeIT from "@angular/common/locales/it";
import { MWDateFormatter } from "./core/lib/date-format.service";

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

registerLocaleData(localeIT, "it-IT");

export const APP_CONTACTS = {

  address: "Strada San Secondo, 15 — 12060 — Pocapaglia di Bra (CN)",
  tel: +390110243459,
  email: "info@most.wine",
  fax: +390110243405

};

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloak.config,
      initOptions: {
        onLoad: "login-required",
        checkLoginIframe: false
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: ["/assets"]
    });
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    FullModule,
    RouterModule.forRoot(AppRoutes, {}),
    CtFrameworkModule.forRoot(),
    CtWebapiModule.setup(
      CtWebapiSetup
        .create(environment.dev.REST_API_SERVER)
        .setHideSpinnerPatterns([/graphql/g])
    ),
    HttpClientModule,
    SpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ApolloModule,
    KeycloakAngularModule,
    CondizioniContrattualiModule,
    ConfirmModule
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: "it-IT" },
    { provide: MAT_DATE_LOCALE, useValue: "it-IT" },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({ uri: environment.dev.GRAPHQL_SERVER })
        };
      },
      deps: [HttpLink]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    MWDateFormatter
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
