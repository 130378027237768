import { ChangeDetectorRef, Component, Input, Optional, TemplateRef, ViewChild } from "@angular/core";
import { MwDatatableConfiguration } from "../../../../components/mw-datatable/mw-datatable.configuration";
import { BaseController } from "../../../../core/controllers/base.controller";
import { CTDatatableConfiguration } from "@ctsolution/ct-framework";
import { QueriesService } from "../../../../core/lib/queries.service";
import {
  clienteHeader,
  quantitaBottiglieSfuseHeader,
  quantitaScatoleHeader,
  quantitaTotaleHeader,
  vinoDescrizioneHeader, vinoProduttoreHeader
} from "../../deposit-stock-datatable.headers";
import { ApolloVariableClass } from "../../../../core/classes/apollo/apollo.variable";
import { GiacenzaViniApolloResult } from "../../../../core/interfaces/apollo/giacenza-vini.apollo-result";
import { BaseApolloQueryClass } from "../../../../core/classes/apollo/apollo.base.query";
import { RoleService } from "../../../../core/lib/role.service";
import {
  SectionDetailDataConfiguration
} from "../../../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { GiacenzaVinoModel } from "../../../../core/interfaces/giacenza-vino.model";
import { MatLegacyDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ModelDetailService } from "../../../../components/model-detail/model-detail.service";
import { GeneralService } from "../../../../core/lib/general.service";
import {
  WineStockShipmentTypeDialogComponent
} from "./wine-stock-shipment-type/wine-stock-shipment-type-dialog.component";
import { TipoSpedizioneEnum } from "../../../../core/enum/tipo-spedizione.enum";
import { WineStockDatatableConfiguration } from "./wine-stock-datatable.configuration";
import { TotaliGiacenzaViniApolloResult } from "../../../../core/interfaces/apollo/totali-giacenza-vini.apollo-result";

@Component({
  selector: "app-wine-stock-datatable",
  templateUrl: "./wine-stock-datatable.component.html"
})
export class WineStockDatatableComponent {

  @Input() configuration: WineStockDatatableConfiguration | null = null;
  @ViewChild("tracking") trackingButtonTemplate: TemplateRef<any> | null = null;

  listConfiguration: MwDatatableConfiguration<GiacenzaViniApolloResult> | null = MwDatatableConfiguration
    .create<GiacenzaViniApolloResult>()
    .setFetchDataCaller(async (variables: ApolloVariableClass) => {

      let query = await this._queries.entities.giacenzaVini();

      if (!query) return null;

      const parameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(query);

      if (this.configuration?.whereAndFilters) {

        parameter
          .variables
          .whereAndFilters
          ?.push(...this.configuration.whereAndFilters);

      }

      return this.baseController.list<GiacenzaViniApolloResult>(parameter);

    })
    .setFetchResponseDataMapper((value: GiacenzaViniApolloResult) => value.giacenzaVini)
    .setCTDatatableConfiguration(
      CTDatatableConfiguration
        .create<GiacenzaViniApolloResult>()
        .setEmptyMessage("Nessuna giacenza.")
        .setLoadingBody("Caricamento dati giacenze. Attendere prego."));

  constructor(
    private baseController: BaseController,
    private _queries: QueriesService,
    private cdr: ChangeDetectorRef,
    public _role: RoleService,
    private dialog: MatLegacyDialog,
    private generalService: GeneralService,
    private modelDetailService: ModelDetailService,
    @Optional() private dialogRef: MatDialogRef<any>) {
  }

  async ngAfterViewInit() {

    const qtaTotaleHeader = quantitaTotaleHeader();
    const qtaScatoleHeader = quantitaScatoleHeader();
    const qtaBottiglieSfuseHeader = quantitaBottiglieSfuseHeader();

    this.listConfiguration?.CTDatatableConfiguration?.setCustomActionTemplate(this.trackingButtonTemplate);

    const totaliBottiglie = await this.getTotaliBottiglie();

    if (totaliBottiglie?.quantitaBottiglieTotale) qtaTotaleHeader.setLabel(`N ${totaliBottiglie.quantitaBottiglieTotale} ${qtaTotaleHeader.label}`);

    if (totaliBottiglie?.quantitaScatole) qtaScatoleHeader.setLabel(`N ${totaliBottiglie.quantitaScatole} ${qtaScatoleHeader.label}`);

    if (totaliBottiglie?.quantitaBottiglieSfuse) qtaBottiglieSfuseHeader.setLabel(`N ${totaliBottiglie.quantitaBottiglieSfuse} ${qtaBottiglieSfuseHeader.label}`);

    const headers = [
      vinoDescrizioneHeader,
      qtaTotaleHeader,
      qtaScatoleHeader,
      qtaBottiglieSfuseHeader,
      vinoProduttoreHeader
    ];

    if (this._role.isAdmin()) headers.unshift(clienteHeader);

    this.listConfiguration
      ?.CTDatatableConfiguration
      ?.setHeaders(headers);

    this.cdr.detectChanges();

  }

  async getTotaliBottiglie() {

    try {

      const totaliBottiglie_query = await this._queries.entities.totaliGiacenzaVini();

      if (!totaliBottiglie_query) return null;

      const parameter = BaseApolloQueryClass.create().setQuery(totaliBottiglie_query);
      const caller = await this.baseController.list<TotaliGiacenzaViniApolloResult | null>(parameter);

      const result = await new Promise<TotaliGiacenzaViniApolloResult | null>((resolve, reject) => {
        caller?.subscribe({ next: resolve, error: reject });
      });

      return (result?.totaliGiacenzaVini ?? []).length ? result?.totaliGiacenzaVini[0] : null;

    } catch (error) {

      console.error("Errore durante la richiesta dei dati:", error);
      return null;

    }

  }

  async onTracking(giacenza: GiacenzaVinoModel) {

    if (this.dialogRef) {

      const sections: SectionDetailDataConfiguration[] = this.modelDetailService.getGiacenzaScatolaSectionsFromGiacenzaVinoModel(giacenza);

      this.dialogRef
        .close(sections);

    } else {

      const { vino, quantita, quantitaScatole } = giacenza;

      if (!vino?.id || !quantita) return;

      if (quantitaScatole) { // se esistono scatole, do la possibilità di fargli scegliere che tipologia di spedizione fare

        const result = await this.dialog.open(WineStockShipmentTypeDialogComponent).afterClosed().toPromise();

        if (result) this.shipmentCreateRedirect(vino.id, result);

      } else {

        // può solo comporre un box
        this.shipmentCreateRedirect(vino.id, TipoSpedizioneEnum.ComposizioneBox);

      }

    }

  }

  private shipmentCreateRedirect(giacenzaProdottoId: string, tipoSpedizione?: TipoSpedizioneEnum) {

    const queryParams: any = { giacenzaProdottoId };

    if (tipoSpedizione) {

      queryParams.tipoSpedizione = tipoSpedizione;

    }

    this.generalService
      .navigateTo("/spedizioni/creazione", { queryParams });

  }

}
