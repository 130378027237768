import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ProductService } from "./product.service";
import { MwDatatableConfiguration } from "../../components/mw-datatable/mw-datatable.configuration";
import { BaseController } from "../../core/controllers/base.controller";
import {
  annoHeader, tipologiaHeader,
  denominazioneHeader, gradazioneHeader, menzioneHeader,
  descrizioneHeader, paeseHeader, produttoreHeader,
  tipoVinoHeader
} from "./product-datatable.headers";
import {
  CTDatatableButtonConfiguration,
  CTDatatableConfiguration,
  CTDatatablePermissions
} from "@ctsolution/ct-framework";
import { QueriesService } from "../../core/lib/queries.service";
import { ViniApolloResult } from "../../core/interfaces/apollo/vini.apollo-result";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { ProdottoModel } from "../../core/classes/prodotto";
import { BaseApolloQueryClass } from "../../core/classes/apollo/apollo.base.query";
import { MwDatatableComponent } from "../../components/mw-datatable/mw-datatable.component";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  providers: [ProductService]
})
export class ProductComponent {

  @ViewChild("table") table: MwDatatableComponent<ProdottoModel | null> | null = null;

  listConfiguration: MwDatatableConfiguration<ViniApolloResult> = MwDatatableConfiguration
    .create<ViniApolloResult>()
    .setFetchDataCaller(async (variables: ApolloVariableClass) => {

      const query = await this._queries.entities.vini();

      if (!query) return null;

      const parameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(query);

      return this.baseController.list<ViniApolloResult>(parameter);

    })
    .setFetchResponseDataMapper((value: ViniApolloResult) => value.vini)
    .setCTDatatableConfiguration(
      CTDatatableConfiguration
        .create<ViniApolloResult>()
        .setEmptyMessage("Nessun vino trovato.")
        .setLoadingBody("Caricamento vini. Attendere prego.")
        .setPermissions([CTDatatablePermissions.insert, CTDatatablePermissions.update, CTDatatablePermissions.read])
        .setCreateButtonConfiguration(
          CTDatatableButtonConfiguration
            .create()
            .setLabel("Crea vino")
        )
        .setHeaders([
          descrizioneHeader,
          tipoVinoHeader,
          denominazioneHeader,
          annoHeader,
          menzioneHeader,
          produttoreHeader,
          tipologiaHeader,
          paeseHeader,
          gradazioneHeader
        ])
    );

  constructor(
    private productService: ProductService,
    private baseController: BaseController,
    private cdr: ChangeDetectorRef,
    private _queries: QueriesService) {
  }

  ngAfterViewInit() {

    this.cdr.detectChanges();

  }

  ngOnInit() {
  }

  async onSave(event: ProdottoModel | null) {

    this.productService
      .setupProduct(event)
      .then(success => {

        if (success) {

          this.table
            ?.reinit();

        }

      });

  }

  detail(prodotto: ProdottoModel) {

    if (!prodotto.id) return;

    this.productService
      .getProductDialogDetail(prodotto.id);

  }

}
