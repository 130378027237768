import { Component, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CtControlValidator, CtSelectControlValue } from "@ctsolution/ct-framework";
import { IndirizzoModel } from "../../../core/classes/indirizzo";
import { AddressControlComponent } from "./address-control/address-control.component";
import { ComuneControlComponent } from "./comune-control/comune-control.component";
import { ProvinciaControlComponent } from "./provincia-control/provincia-control.component";
import { CapControlComponent } from "./cap-control/cap-control.component";
import { PaeseControlComponent } from "../../paese-control/paese-control.component";

@Component({
  selector: "app-address-data-form",
  templateUrl: "./address-data-form.component.html"
})
export class AddressDataFormComponent {

  @ViewChild(AddressControlComponent) addressControlComponent: AddressControlComponent | null = null;
  @ViewChild(ComuneControlComponent) comuneControlComponent: ComuneControlComponent | null = null;
  @ViewChild(ProvinciaControlComponent) provinciaControlComponent: ProvinciaControlComponent | null = null;
  @ViewChild(CapControlComponent) capControlComponent: CapControlComponent | null = null;
  @ViewChild(PaeseControlComponent) statoControlComponent: PaeseControlComponent | null = null;

  form: FormGroup;

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    this.setupForm();

  }

  setupForm() {

    this.addressControlComponent
      ?.control
      .setFormParent(this.form);

    this.comuneControlComponent
      ?.control
      .setFormParent(this.form);

    this.provinciaControlComponent
      ?.control
      .setFormParent(this.form);

    this.capControlComponent
      ?.control
      .setFormParent(this.form);

    this.setupStatoControl();

  }

  setupValues(value: IndirizzoModel | null | undefined, setupDisable : boolean = true) {

    if(value) {

      this.form
      .patchValue(value);

      if(setupDisable){  
        this.addressControlComponent
        ?.control
        .setDisabled(true);
      this.comuneControlComponent
        ?.control
        .setDisabled(true);
      this.provinciaControlComponent
        ?.control
        .setDisabled(true);
      this.capControlComponent
        ?.control
        .setDisabled(true);
      this.statoControlComponent
        ?.configuration
        ?.CTControl
        ?.setDisabled(true);
    }

    if (value.stato) {

      this.statoControlComponent
        ?.configuration
        ?.CTControl
        .setValue(
          CtSelectControlValue
            .create()
            .setLabel(value.stato.nome)
            .setValue(value.stato));

    }
      
    }
    else{

      this.form.reset();
      this.addressControlComponent
      ?.control
      .setDisabled(false);
    this.comuneControlComponent
      ?.control
      .setDisabled(false);
    this.provinciaControlComponent
      ?.control
      .setDisabled(false);
    this.capControlComponent
      ?.control
      .setDisabled(false);
    this.statoControlComponent
      ?.configuration
      ?.CTControl
      ?.setDisabled(false);

    this.setupStatoControl();

    }
   

  }

  private setupStatoControl() {

    this.statoControlComponent
      ?.configuration
      ?.CTControl
      ?.setName("stato")
      ?.setFormParent(this.form)
      ?.setValidators([CtControlValidator.create({ name: "required" } as CtControlValidator)]);

  }

}
