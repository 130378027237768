import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";
import { TranslateService } from "@ngx-translate/core";

export const nominativoHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("nomeRiferimento")
  .setLabel(translate.instant('CT_DATATABLE.CLIENTRECORDS.FullName'))
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant('CT_DATATABLE.CLIENTRECORDS.NameSearch'))
      .setShowMenu(true));

export const ragioneSocialeHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("nomeRiferimento")
  .setLabel(translate.instant('CT_DATATABLE.CLIENTRECORDS.CompanyName'))
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant('CT_DATATABLE.CLIENTRECORDS.CompanyNameSearch'))
      .setShowMenu(true));

export const indirizzoPrincipaleHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzo.via")
  .setLabel(translate.instant('CT_DATATABLE.CLIENTRECORDS.ResidentialAddress'))
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant('CT_DATATABLE.CLIENTRECORDS.AddressSearch'))
      .setShowMenu(true));

export const indirizzoPrincipaleProvinciaHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzo.provincia")
  .setLabel(translate.instant('CT_DATATABLE.CLIENTRECORDS.Province'))
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant('CT_DATATABLE.CLIENTRECORDS.ProvinceSearch'))
      .setShowMenu(true));

export const indirizzoPrincipaleStatoHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("indirizzo.stato.nome")
  .setLabel(translate.instant('CT_DATATABLE.CLIENTRECORDS.State'))
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant('CT_DATATABLE.CLIENTRECORDS.StateSearch'))
      .setShowMenu(true));
