<div class="deposit-collection-packaging-container">

  <h3> Riepilogo del tuo deposito </h3>

  <div class="recap-selected-items-panel">

    <ng-container *ngFor="let packageInfo of (configuration?.depositFormValue?.step2?.colliStoccaggio?.packages ?? [])">

      <ng-container *ngFor="let wine of packageInfo.box.wines">

        <app-section-detail-data
          [configuration]="depositCollectionPackagingService.getSectionDetailConfiguration(wine)"></app-section-detail-data>

      </ng-container>

    </ng-container>

  </div>

  <div
    [hidden]="!configuration?.needsImballoRitiro">

    <h3> Specifica i colli per la spedizione: </h3>

    <div
      *ngFor="let packageForm of (collection?.controls ?? []); let i = index; let last = last;">

      <app-collection-form
        [configuration]="configuration"
        [packageForm]="packageForm"
        [deleteEnabled]="(collection?.controls ?? []).length > 1"
        (onRemove)="onRemove.emit(i)"></app-collection-form>

      <hr *ngIf="!last && collection.controls.length" />

    </div>

    <div align="end">

      <button
        mat-button
        class="general-button m-t-10"
        style="min-height: 48px"
        (click)="addCollection()"> {{ 'Aggiungi imballo' | translate }}
      </button>

    </div>

  </div>

</div>
