import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShipmentComponent } from "./shipment.component";
import { RouterModule } from "@angular/router";
import { CtButtonModule, CtCardModule, CTDatatableModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MwDatatableModule } from "../../components/mw-datatable/mw-datatable.module";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { ShipmentService } from "./shipment.service";
import { ModelDetailModule } from "../../components/model-detail/model-detail.module";
import { ShipmentCreateWizardModule } from "./shipment-create-wizard/shipment-create-wizard.module";
import { ShipmentCreateWizardComponent } from "./shipment-create-wizard/shipment-create-wizard.component";
import { MWDateFormatter } from "../../core/lib/date-format.service";
import { BulletStateModule } from "../../components/bullet-state/bullet-state.module";
import { StateLegendModule } from "../../components/state-legend/state-legend.module";
import { CourierRateTableModule } from "../../components/courier-rate-table/courier-rate-table.module";

@NgModule({
  declarations: [ShipmentComponent],
  imports: [
    CommonModule,
    CtButtonModule,
    FlexLayoutModule,
    ShipmentCreateWizardModule,
    MatDialogModule,
    CtCardModule,
    RouterModule.forChild([
      {
        path: "creazione",
        component: ShipmentCreateWizardComponent,
        data: {
          title: "Crea nuova spedizione"
        }
      },
      {
        path: "",
        component: ShipmentComponent,
        data: {
          title: "Le tue spedizioni",
          urls: []
        }
      }
    ]),
    MwDatatableModule,
    TranslateModule,
    MatIconModule,
    CTDatatableModule,
    ModelDetailModule,
    BulletStateModule,
    StateLegendModule,
    CourierRateTableModule
  ],
  providers: [
    ShipmentService,
    MWDateFormatter
  ]
})
export class ShipmentModule {
}


