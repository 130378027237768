import { Component } from "@angular/core";
import {
  MwCardRadioOptionConfiguration
} from "../../../../../../components/mw-card-radio-group/mw-card-radio-option/mw-card-radio-option.configuration";
import { iconsBasePath } from "../../../../../../core/constants";
import { ModalitaStoccaggio } from "../../../../../../core/enum/modalita-stoccaggio.enum";
import { FormControl, Validators } from "@angular/forms";
import {
  MwCardRadioGroupConfiguration
} from "../../../../../../components/mw-card-radio-group/mw-card-radio-group.configuration";

const PackageTypeCardRadioOptionConfigurations: MwCardRadioOptionConfiguration<ModalitaStoccaggio>[] = [
  MwCardRadioOptionConfiguration
    .create<ModalitaStoccaggio>()
    .setImage(`${iconsBasePath}box.svg`)
    .setValue(ModalitaStoccaggio.Scatola)
    .setLabel(`Aggiungi scatola`),
  MwCardRadioOptionConfiguration
    .create<ModalitaStoccaggio>()
    .setValue(ModalitaStoccaggio.Bottiglia)
    .setImage(`${iconsBasePath}bottles.svg`)
    .setLabel(`Aggiungi bottiglie`)
];

@Component({
  selector: "app-package-type-selection",
  template: `
    <div class="radio-packaging-selector">
      <mw-card-radio-group [configuration]="mwCardRadioGroupConfiguration"></mw-card-radio-group>
    </div>`
})
export class PackageTypeSelectionComponent {

  control: FormControl = new FormControl(null, Validators.required);

  mwCardRadioGroupConfiguration: MwCardRadioGroupConfiguration<ModalitaStoccaggio> = MwCardRadioGroupConfiguration
    .create<ModalitaStoccaggio>()
    .setControl(this.control)
    .setOptions(PackageTypeCardRadioOptionConfigurations)
    .enableHorizontalView(true);

}
