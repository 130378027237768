import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { CtButtonConfiguration } from "@ctsolution/ct-framework";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfirmService } from "../../../components/confirm/confirm.service";
import { BaseController } from "../../../core/controllers/base.controller";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { CustomerFormConfiguration } from "./customer-form.configuration";
import { CustomerControllerRequest } from "../../../core/controllers/controllers";
import { ClienteModel } from "../../../core/classes/cliente";
import { TipoClienteEnum } from "../../../core/enum/tipo-cliente.enum";
import { CustomerDataFormComponent } from "./customer-data-form/customer-data-form.component";
import {
  AddressDataFormComponent
} from "../../../components/shipment-address-form/address-data-form/address-data-form.component";
import { IndirizzoModel } from "../../../core/classes/indirizzo";
import { AddressesDatatableConfiguration } from "../../addresses/addresses-datatable/addresses-datatable.configuration";

@Component({
  selector: "app-customer-form",
  templateUrl: "./customer-form.component.html"
})
export class CustomerFormComponent {

  @ViewChild(CustomerDataFormComponent) customerDataFormComponent: CustomerDataFormComponent | null = null;
  @ViewChild(AddressDataFormComponent) addressDataFormComponent: AddressDataFormComponent | null = null;

  form: FormGroup;
  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CTPAGE.CTFORM.Save")
    .setClass("general-button save")
    .setAction(() => this.submit());

  addressesDatatableConfiguration: AddressesDatatableConfiguration = AddressesDatatableConfiguration
    .create();

  tabIndex: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private confirmService: ConfirmService,
    private baseController: BaseController,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatLegacyDialogRef<CustomerFormComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public configuration: CustomerFormConfiguration) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    this.setupForm();

  }

  setupForm() {

    this.form
      .addControl("CustomerData", this.customerDataFormComponent?.form);

    this.form
      .addControl("AddressData", this.addressDataFormComponent?.form);

    this.setupValues();

  }

  async setupValues() {

    if (this.configuration.values) {

      const values = this.configuration.values;

      this.addressesDatatableConfiguration
        .setCliente(values);

      if (this.configuration.values.indirizzoPrincipale) {

        this.addressDataFormComponent
          ?.setupValues(this.configuration.values.indirizzoPrincipale);

      }

      this.customerDataFormComponent
        ?.form
        ?.patchValue(values);

    }

    this.cdr.detectChanges();

  }

  close() {

    if (this.form?.touched) {

      const dialogRef = this.confirmService.open();

      dialogRef
        .afterClosed()
        .subscribe((result?: boolean) => {

          if (result) this.dialogRef.close();

        });

      return;

    }

    this.dialogRef.close();

  }

  async submit() {

    this.form?.markAllAsTouched();

    if (this.form.valid && this.configuration.tipoCliente) {

      const value = this.form.value as { AddressData: IndirizzoModel, CustomerData: ClienteModel };

      const updatedResult = this.processCustomerResult(this.configuration.tipoCliente, value);

      const parameter = CustomerControllerRequest();

      parameter
        .setId(this.configuration?.values?.id ?? null);

      await this.baseController.createOrUpdate<ClienteModel>(updatedResult, parameter);

      this.dialogRef?.close(true);

    }

  }

  private processCustomerResult(tipoCliente: TipoClienteEnum, value: {
    AddressData: IndirizzoModel,
    CustomerData: ClienteModel
  }): ClienteModel {

    return ClienteModel
      .create(value.CustomerData)
      .setId(value.CustomerData.id ?? null)
      .setDataDiNascita(value.CustomerData.dataDiNascita)
      .setTipoCliente(tipoCliente)
      .setIndirizzo(value.AddressData);

  }

  protected readonly TipoClienteEnum = TipoClienteEnum;

}
