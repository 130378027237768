import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CondizioniContrattualiComponent } from "./condizioni-contrattuali.component";
import { CondizioniContrattualiService } from "./condizioni-contrattuali.service";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { FlexModule } from "@angular/flex-layout";


@NgModule({
  declarations: [CondizioniContrattualiComponent],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    FlexModule
  ],
  providers: [CondizioniContrattualiService]
})
export class CondizioniContrattualiModule {
}
