<mat-card>
  <mat-card-content>
    <mat-card-title><strong>I tuoi clienti</strong></mat-card-title>
    <mat-card-subtitle>Privati Vs Business</mat-card-subtitle>
    <div id="chart" class="d-flex flex-column">
      <apx-chart
        #chart
        *ngIf="chartOptions"
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis">
      </apx-chart>
    </div>
  </mat-card-content>
</mat-card>
