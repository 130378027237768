import { Injectable } from "@angular/core";
import { GiacenzaVinoModel } from "../../../core/interfaces/giacenza-vino.model";
import {
  ShipmentCollectionConfiguration
} from "./shipment-create-step1/shipment-collection/shipment-collection.configuration";
import {
  SectionDetailDataConfiguration
} from "../../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { ModelDetailService } from "../../../components/model-detail/model-detail.service";

@Injectable({
  providedIn: "root"
})
export class ShipmentCreateWizardService {

  constructor(private modelDetailService: ModelDetailService) {
  }

  productCountInsideCollection(giacenzaVino: GiacenzaVinoModel, collection: ShipmentCollectionConfiguration[]) {

    const collections = (collection ?? [])
      .map((element: any) => ({
        sections: (<ShipmentCollectionConfiguration>element.data)?.sections ?? [],
        quantity: element.quantita ?? 1
      })) as { sections: SectionDetailDataConfiguration[], quantity: number }[] | null;

    let contaProdotti: number = 0;

    collections?.forEach(
      collection => {
        collection.sections.forEach(section => {
          if (section.attributes?.prodottoId === giacenzaVino?.vino?.id) {
            const detailData = this.modelDetailService.findDetailDataByTitle(section, "bottiglie");
            contaProdotti += (+(detailData?.cardConfiguration?.description ?? "0")) * collection.quantity;
          }
        });
      });

    return contaProdotti;

  }

}
