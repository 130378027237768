import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TipoClienteEnum } from "../../../../core/enum/tipo-cliente.enum";
import { CtDateControlOptions, CtFormConfiguration } from "@ctsolution/ct-framework";
import { CustomerFormConfiguration } from "../customer-form.configuration";
import { GenericCustomerData } from "../../_forms/generic-customer.form-configuration";
import { BusinessCustomerData } from "../../_forms/business-customer.form-configuration";

@Component({
  selector: "app-customer-data-form",
  templateUrl: "./customer-data-form.component.html"
})
export class CustomerDataFormComponent {

  @Input() configuration: CustomerFormConfiguration | null = null;

  form: FormGroup;
  formConfiguration: CtFormConfiguration | null = null;

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    if (!this.configuration?.tipoCliente) return;

    this.formConfiguration = CtFormConfiguration.create(this.form, this.getConfigurationForTipoCliente(this.configuration.tipoCliente));

    const dataDiNascita = this.formConfiguration
      .controls
      ?.find(cntrl => cntrl.name === "dataDiNascita");

    if (dataDiNascita) {

      const endDate = new Date();

      endDate
        .setFullYear(endDate.getFullYear() - 18);

      (<CtDateControlOptions>dataDiNascita
        .options)
        .setMax(endDate);

    }

  }

  private getConfigurationForTipoCliente(tipoCliente: TipoClienteEnum): CtFormConfiguration {

    switch (tipoCliente) {
      case TipoClienteEnum.ClienteBusiness:
        return BusinessCustomerData();
      case TipoClienteEnum.ClientePrivate:
      default:
        return GenericCustomerData();
    }

  }

}
