import {
    CtControlConfiguration,
    CtControlTypes,
    CtFormConfiguration,
    CtThemeTypes,
    Dictionary
  } from "@ctsolution/ct-framework";
  
  const ProfileBusinessUpdateControls: CtControlConfiguration[] = [
    <CtControlConfiguration>{
      name: "nomeRiferimento",
      label: "CT_DATATABLE.CLIENTRECORDS.FullName",
      type: CtControlTypes.TEXT,
      flexSpacePercentage: 100,
      flexSpaceBreakpoints: <Dictionary<number>>{
        xs: 100,
        sm: 100
      },
      validators: [
        { name: "required" }
      ],
      theme: CtThemeTypes.MATERIAL

    },
    <CtControlConfiguration>{
        name: "ragioneSociale",
        label: "CT_GENERAL.company_name",
        flexSpacePercentage: 50,
        flexSpaceBreakpoints: <Dictionary<number>>{
          xs: 100,
          sm: 100
        },
        validators: [
          { name: "required" },
          { name: "min", value: 0 },
          { name: "maxlength", value: 60 }
        ]
      },
    <CtControlConfiguration>{
        name: "codiceFiscale",
        label: "CT_GENERAL.fiscal_code",
        type: CtControlTypes.TEXT,
        flexSpacePercentage: 50,
        flexSpaceBreakpoints: <Dictionary<number>>{
          xs: 100,
          sm: 100
        },
        validators: [
          { name: "required" }
        ],
        theme: CtThemeTypes.MATERIAL

      },
    <CtControlConfiguration>{
      name: "telefono",
      label: "CT_GENERAL.phone_number",
      type: CtControlTypes.TEXT,
      flexSpacePercentage: 50,
      flexSpaceBreakpoints: <Dictionary<number>>{
        xs: 100,
        sm: 100
      },
      validators: [
        { name: "required" },
        { name: "min", value: 0 },
        { name: "maxlength", value: 40 }
  
      ],
      theme: CtThemeTypes.MATERIAL

    },
    <CtControlConfiguration>{
        name: "partitaIva",
        label: "CT_GENERAL.vat_number",
        type: CtControlTypes.TEXT,
        flexSpacePercentage: 50,
        flexSpaceBreakpoints: <Dictionary<number>>{
          xs: 100,
          sm: 100
        },
        validators: [
          { name: "required" },
          { name: "min", value: 0 },
          { name: "maxlength", value: 11 }
    
        ],
        theme: CtThemeTypes.MATERIAL
  
      },

  ];
  export const ProfileBusinessUpdateFormConfiguration: CtFormConfiguration = <CtFormConfiguration>{
    controls: ProfileBusinessUpdateControls
  };

  
  const ProfilePrivateUpdateControls: CtControlConfiguration[] = [
    <CtControlConfiguration>{
        name: "nomeRiferimento",
        label: "CT_DATATABLE.CLIENTRECORDS.FullName",
        type: CtControlTypes.TEXT,
        flexSpacePercentage: 100,
        flexSpaceBreakpoints: <Dictionary<number>>{
          xs: 100,
          sm: 100
        },
        validators: [
          { name: "required" }
        ],
        theme: CtThemeTypes.MATERIAL
  
      },
    <CtControlConfiguration>{
      name: "cognome",
      label: "CT_GENERAL.surname",
      type: CtControlTypes.TEXT,
      flexSpacePercentage: 50,
      flexSpaceBreakpoints: <Dictionary<number>>{
        xs: 100,
        sm: 100
      },
      validators: [
        { name: "required" }
      ],
      theme: CtThemeTypes.MATERIAL

    },
    <CtControlConfiguration>{
        name: "nome",
        label: "CT_GENERAL.name",
        type: CtControlTypes.TEXT,
        flexSpacePercentage: 50,
        flexSpaceBreakpoints: <Dictionary<number>>{
          xs: 100,
          sm: 100
        },
        validators: [
          { name: "required" }
        ],
        theme: CtThemeTypes.MATERIAL
  
      },
    <CtControlConfiguration>{
        name: "codiceFiscale",
        label: "CT_GENERAL.fiscal_code",
        type: CtControlTypes.TEXT,
        flexSpacePercentage: 100,
        flexSpaceBreakpoints: <Dictionary<number>>{
          xs: 100,
          sm: 100
        },
        validators: [
          { name: "required" }
        ],
        theme: CtThemeTypes.MATERIAL

      },
    <CtControlConfiguration>{
      name: "telefono",
      label: "CT_GENERAL.phone_number",
      type: CtControlTypes.TEXT,
      flexSpacePercentage: 50,
      flexSpaceBreakpoints: <Dictionary<number>>{
        xs: 100,
        sm: 100
      },
      validators: [
        { name: "required" },
        { name: "min", value: 0 },
        { name: "maxlength", value: 40 }
  
      ],
      theme: CtThemeTypes.MATERIAL

    },
    <CtControlConfiguration>{
        name: "dataDiNascita",
        label: "CT_GENERAL.birthday",
        type: CtControlTypes.DATE,
        flexSpacePercentage: 50,
        flexSpaceBreakpoints: <Dictionary<number>>{
          xs: 100,
          sm: 100
        },
        validators: [
          { name: "required" }
        ],
        theme: CtThemeTypes.MATERIAL
  
      },

  ];
  export const ProfilePrivateUpdateFormConfiguration: CtFormConfiguration = <CtFormConfiguration>{
    controls: ProfilePrivateUpdateControls
  };