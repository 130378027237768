import { Component, OnInit } from "@angular/core";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";
import { QueriesService } from "../../../core/lib/queries.service";
import { BaseController } from "../../../core/controllers/base.controller";
import { NotificheApolloResult } from "../../../core/interfaces/apollo/notifiche.apollo-result";
import { DepositoModel } from "../../../core/classes/deposito/deposito";
import { SpedizioneModel } from "../../../core/classes/spedizione/spedizione.model";

@Component({
  selector: "app-notifications-widget",
  templateUrl: "./notifications-widget.component.html"
})
export class NotificationsWidgetComponent implements OnInit {

  notifications: { from: string; time: Date; content: string }[] = [];

  constructor(private _queries: QueriesService, private baseController: BaseController) {
  }

  notificationFormatter(value: any) {
    return {
      time: new Date(value.dataCreazione),
      from: value.codice,
      content: value.destinatario
        ? `Una spedizione è partita dal Deposito Most per ${value.destinatario?.nome}.`
        : `Un deposito è stato effettuato. Contenuto: ${value.descrizione}.`
    };
  };

  async ngOnInit() {

    await this.getNotifications();

  }

  async getNotifications() {

    const query = await this._queries.entities.notifiche();

    if (!query) return null;

    const parameter = BaseApolloQueryClass
      .create()
      .setQuery(query);

    const caller = await this.baseController.list<NotificheApolloResult>(parameter);

    if (!caller) return null;

    caller
      ?.subscribe(result => {

        const depositi: Array<DepositoModel> = result.depositi.nodes;
        const spedizioni: Array<SpedizioneModel> = result.spedizioni.nodes;

        const combinedArray = [...depositi, ...spedizioni];

        const sortedArray = combinedArray.sort((a, b) => new Date((<any>b).dataCreazione).getTime() - new Date((<any>a).dataCreazione).getTime());

        sortedArray
          .forEach(elm => this.notifications.push(this.notificationFormatter(elm)));

      });

  }

}
