import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { ChartOptions } from "chart.js";
import { BaseController } from "../../../core/controllers/base.controller";
import { DepositorService } from "../../../core/lib/depositor.service";
import { QueriesService } from "../../../core/lib/queries.service";
import { ApolloVariableClass } from "../../../core/classes/apollo/apollo.variable";
import { TipoClienteEnum } from "../../../core/enum/tipo-cliente.enum";
import { ClientiApolloResult } from "../../../core/interfaces/apollo/clienti.apollo-result";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";

@Component({
  selector: "app-customers-counter-widget",
  templateUrl: "./customers-counter-widget.component.html"
})
export class CustomersCounterWidgetComponent {

  @ViewChild("chart") chart: ChartComponent = Object.create(null);
  public chartOptions: Partial<ChartOptions> | any | null = null;

  constructor(
    private depositorService: DepositorService,
    private baseController: BaseController,
    private _queries: QueriesService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const depositanteId = await this.depositorService.getDepositor();
    const clienti_query = await this._queries.entities.clienti();

    const chartOptions: any = {
      series: [
        { name: "Business", data: [] },
        { name: "Privati", data: [] }
      ],
      chart: {
        type: "bar",
        fontFamily: "Poppins,sans-serif",
        height: 347
      },
      xaxis: {
        categories: ["Clienti MOST'"]
      }
    };

    if (clienti_query) {

      const variables = ApolloVariableClass.create().setDepositanteId(depositanteId);
      const clientiParameter = BaseApolloQueryClass.create().setVariables(variables).setQuery(clienti_query);

      const tipoClienteArray = [TipoClienteEnum.ClienteBusiness, TipoClienteEnum.ClientePrivate];

      for (let i = 0; i < tipoClienteArray.length; i++) {

        clientiParameter.variables.setTipoCliente(tipoClienteArray[i]);
        const clientiCaller = await this.baseController.list<ClientiApolloResult>(clientiParameter);

        if (clientiCaller) {

          clientiCaller
            .subscribe(response => {

              chartOptions.series[i].data.push(response.clienti.totalCount);
              this.cdr.detectChanges(); // Forza la change detection

              if (i === tipoClienteArray.length - 1) {

                this.chartOptions = chartOptions;
                // this.chart.render();

              }

            });

        }

      }

    }

  }

}
