<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

  {{ 'CT_MODALFORM.ClientCreation' | translate }}

  <button
    mat-icon-button color="primary" (click)="close()">

    <mat-icon>close</mat-icon>

  </button>

</div>

<div mat-dialog-content>

  <p-tabView class="tab-view" [(activeIndex)]="tabIndex">

    <p-tabPanel [header]="'CT_MODALFORM.GeneralData' | translate">

      <form [formGroup]="form">

        <div>

          <app-customer-data-form [configuration]="configuration"></app-customer-data-form>

        </div>

         <div>

          <h3> {{ 
            (configuration.tipodestinazione === TipodestinazioneEnum.destinazioneBusiness 
              ? 'CT_DATATABLE.CLIENTRECORDS.LegalAddress' 
              : 'CT_DATATABLE.CLIENTRECORDS.CreationResidence') 
            | translate 
          }}</h3>

          <app-address-data-form></app-address-data-form>

        </div> 

      </form>

    </p-tabPanel>

    <!-- <p-tabPanel [header] = "'CT_DATATABLE.CLIENTRECORDS.InfoAddress' | translate" *ngIf="addressesDatatableConfiguration.destinazione">

      <app-addresses-datatable [configuration]="addressesDatatableConfiguration"></app-addresses-datatable>

    </p-tabPanel> -->

  </p-tabView>

</div>

<div mat-dialog-actions align="end" *ngIf="tabIndex === 0">

  <ct-button [configuration]="submitButton"></ct-button>

</div>
