import { Injectable } from "@angular/core";
import { ModalitaStoccaggio } from "../../../../../core/enum/modalita-stoccaggio.enum";
import { iconsBasePath } from "../../../../../core/constants";

@Injectable({
  providedIn: "root"
})
export class PackageComposerService {

  constructor() {
  }

  getPackageTypeTitleByDepositValue(value: ModalitaStoccaggio | null) {

    switch (value) {

      case ModalitaStoccaggio.Pallet:
        return "Composizione Pallet";
      case ModalitaStoccaggio.Scatola:
        return "Composizione Scatola";
      case ModalitaStoccaggio.Bottiglia:
        return "Aggiunta Bottiglie";
      default:
        return "";

    }

  }

  getPackageTypeIconByDepositValue(value: ModalitaStoccaggio | null) {

    switch (value) {

      case ModalitaStoccaggio.Pallet:
        return `${iconsBasePath}pallet.svg`;

      case ModalitaStoccaggio.Scatola:
        return `${iconsBasePath}box.svg`;

      case ModalitaStoccaggio.Bottiglia:
        return `${iconsBasePath}bottles.svg`;

      default:
        return "";

    }

  }

}
