import { ChangeDetectorRef, Component } from "@angular/core";
import { MwDatatableConfiguration } from "../../components/mw-datatable/mw-datatable.configuration";
import { GeneralService } from "../../core/lib/general.service";
import { BaseController } from "../../core/controllers/base.controller";
import {
  codiceSpedizioneHeader,
  emailDestinatarioSpedizioneHeader,
  nomeDestinatarioSpedizioneHeader,
  dataCreazioneSpedizioneHeader, dataConsegnaSpedizioneHeader, indirizzoSpedizioneHeader, contenutoSpedizioneHeader
} from "./shipment-datatable.headers";
import { ShipmentService } from "./shipment.service";
import {
  CTDatatableButtonConfiguration,
  CTDatatableConfiguration,
  CTDatatablePermissions
} from "@ctsolution/ct-framework";
import { QueriesService } from "../../core/lib/queries.service";
import { SpedizioniApolloResult } from "../../core/interfaces/apollo/spedizioni.apollo-result";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { BaseApolloQueryClass } from "../../core/classes/apollo/apollo.base.query";
import { SpedizioneReadModel } from "../../core/classes/spedizione/spedizione.read-model";
import { statoHeader } from "../deposit/deposit-datatable.headers";

@Component({
  selector: "app-shipment",
  templateUrl: "./shipment.component.html"
})
export class ShipmentComponent {

  listConfiguration: MwDatatableConfiguration<SpedizioniApolloResult> | null = null;

  constructor(
    private generalService: GeneralService,
    private baseController: BaseController,
    public shipmentService: ShipmentService,
    private _queries: QueriesService,
    private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.setupListConfiguration();
    this.cdr.detectChanges();

  }

  setupListConfiguration() {

    this.listConfiguration = MwDatatableConfiguration
      .create<SpedizioniApolloResult>()
      .setFetchDataCaller(async (variables: ApolloVariableClass) => {

        const query = await this._queries.entities.spedizioni();

        if (!variables.order.length) {

          variables
            .setOrder([{ dataCreazione: "DESC" }]);

        }

        if (!query) return null;

        const parameter = BaseApolloQueryClass
          .create()
          .setVariables(variables)
          .setQuery(query);

        return this.baseController.list<SpedizioniApolloResult>(parameter);

      })
      .setFetchResponseDataMapper((value: SpedizioniApolloResult) => value.spedizioni)
      .setCTDatatableConfiguration(
        CTDatatableConfiguration
          .create<SpedizioniApolloResult>()
          .setEmptyMessage("Nessuna spedizione trovata.")
          .setLoadingBody("Caricamento spedizioni. Attendere prego.")
          .setPermissions([CTDatatablePermissions.insert, CTDatatablePermissions.read])
          .setCreateButtonConfiguration(
            CTDatatableButtonConfiguration
              .create()
              .setIcon("pi pi-truck")
              .setLabel("Crea nuova spedizione")
          )
          .setHeaders([
            statoHeader,
            codiceSpedizioneHeader,
            nomeDestinatarioSpedizioneHeader,
            emailDestinatarioSpedizioneHeader,
            dataCreazioneSpedizioneHeader,
            dataConsegnaSpedizioneHeader,
            indirizzoSpedizioneHeader,
            contenutoSpedizioneHeader
          ])
          .setVirtualScrollItemSize(59)
      );

  }

  addShipment() {

    this.generalService
      .navigateTo("/spedizioni/creazione");

  }

  detail(shipment: SpedizioneReadModel) {

    if (!shipment.id) return;

    this.shipmentService.showDetail(shipment.id);

  }

  contenutoSpedizione(value: SpedizioneReadModel) {

    const content = [];

    if ((value?.pallets ?? []).length) {

      const countValue = value?.pallets?.reduce((acc, pallet) => acc + pallet.quantita, 0);
      content.push(`${countValue} Pallet${countValue > 1 ? "s" : ""}`);

    }

    if ((value?.scatole ?? []).length) {

      const countValue = value?.scatole?.reduce((acc, scatola) => acc + scatola.quantita, 0);

      content.push(`${countValue} Scatol${countValue > 1 ? "e" : "a"}`);

    }

    if ((value?.confezioni ?? []).length) {

      const countValue = value?.confezioni?.reduce((acc, confezione) => acc + confezione.quantita, 0);
      const suffix = countValue > 1 ? "e" : "a";

      content.push(`${countValue} Scatol${suffix} personalizzat${suffix}`);

    }

    return content.join(", ");

  }

}


 


