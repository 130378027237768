import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest, MethodEnum } from "@ctsolution/ct-webapi";
import { DepositorService } from "../lib/depositor.service";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApolloQueryResult, TypedDocumentNode } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import { RoleService } from "../lib/role.service";
import { ApolloVariableClass } from "../classes/apollo/apollo.variable";
import { BaseRequestClass } from "../classes/apollo/apollo.base.request";
import { BaseApolloQueryClass } from "../classes/apollo/apollo.base.query";
import { SnackbarService } from "@ctsolution/ct-framework";

@Injectable({
  providedIn: "root"
})
export class BaseController {

  constructor(
    private _webapi: CtWebapiService,
    private _depositor: DepositorService,
    private _apollo: Apollo,
    private snackbar: SnackbarService,
    private _role: RoleService) {
  }

  async list<T>(parameter: BaseApolloQueryClass): Promise<Observable<T> | null> {

    const depositorValue = await this._depositor.getDepositor();

    if (!depositorValue && !this._role.isAdmin())
    {
      console.error('Depositor not found');
      //TODO: Puà portare problemi?
    } 
    else{
      parameter
      .variables
      .setDepositanteId(depositorValue);
    }


    const options = parameter.getOptions();

    return this._apollo
      .query<T>(options) // prima usavo watchQuery, mhh
      .pipe(
        // delay(new Date(Date.now() + 500)),
        map((result: ApolloQueryResult<T>) => result.data));

  }

  async get<T>(id: string | null, query: TypedDocumentNode<{ [p: string]: any }, { [p: string]: any }>) {

    if (!id) return;

    const variables: ApolloVariableClass = ApolloVariableClass
      .create()
      .setId(id);

    const parameter = BaseApolloQueryClass
      .create()
      .setVariables(variables)
      .setQuery(query);

    return this.list<T>(parameter);

  }

  executeStandardRequest(parameter: BaseRequestClass, method: MethodEnum | null) {

    if (!parameter.controller) return null;

    const request: DataRequest = DataRequest
      .create()
      .setController(parameter.controller)
      .setAction(parameter.action)
      .setMethod(method);

    return this._webapi
      .request(request);

  }

  async executeDepositorEntityRequest<T>(parameter: BaseRequestClass) {

    if (!parameter.controller) return null;

    const request: DataRequest | null = await this._depositor.generateDepositorRequestController(parameter);

    if (!request) return null;

    let id = null;

    if (!parameter.bypassIdSetting) id = parameter.id ?? uuidv4();

    let action = [parameter.action ?? null, id].filter(elm => !!elm).join("/");

    request
      .setAction(action);

    if (parameter.dataSource) {

      request
        .setBody(parameter.dataSource);

    }

    // Pulizia della cache di Apollo
    await this._apollo.client.clearStore();

    const toReturn = parameter.id || parameter.forcePut ? this._webapi.put(request) : this._webapi.post(request);

    if (!parameter.id) {

      parameter
        .setId(id); // aggiorno l'id in caso di post

    }

    return toReturn;

  }

  async createOrUpdate<T>(value: T, parameter: BaseRequestClass): Promise<T | null> {

    if (parameter.id) {
      // perchè il form entity non gestisce un control per "id",
      // prende esattamente i campi che vengono visualizzati per le input
      // quindi la datasource restituita non contiene l'id originale (che viene settato però nella baseRequestClass
      //siccome comporrà anche l'api route da raggiungere

      (<any>value)
        .id = parameter.id;

    }

    parameter
      .setDataSource(value);

    const createCaller = await this.executeDepositorEntityRequest<T>(parameter);

    if (!createCaller) {

      this.snackbar.open("È emerso un errore durante l'elaborazione della tua richiesta. Verifica di disporre delle autorizzazioni necessarie per apportare questa modifica.", "X", 5000);
      return null;

    }

    return new Promise<T | null>((resolve) => {

      createCaller
        .subscribe((response) => resolve(response));

    });

  }

}

