import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { MwDatatableConfiguration } from "./mw-datatable.configuration";
import { FilterMetadata } from "primeng/api";
import { CTDatatableComponent, CTDatatableConfiguration, CTDatatableParameter } from "@ctsolution/ct-framework";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { ApolloResultDataList } from "../../core/interfaces/apollo/apollo.result.data-list";
import { MWDateFormatter } from "../../core/lib/date-format.service";
import { Observable } from "rxjs";

@Component({
  selector: "mw-datatable",
  templateUrl: "./mw-datatable.component.html",
  styleUrls: ["./mw-datatable.component.scss"]
})
export class MwDatatableComponent<T> {

  @ContentChild("body", { static: true }) bodyTemplate: TemplateRef<any> | null = null;
  @ContentChild("rowExpansion", { static: true }) rowExpansionTemplate: TemplateRef<any> | null = null;
  @ContentChild("legendTemplate", { static: true }) legendTemplate: TemplateRef<any> | null = null;

  @Input() configuration: MwDatatableConfiguration<T> | null = null;

  @Output() onSave: EventEmitter<T | null> = new EventEmitter<T | null>();
  @Output() onDetail: EventEmitter<T | null> = new EventEmitter<T | null>();
  @ViewChild("datatable") datatable: CTDatatableComponent<T> | null = null;

  constructor(private cdr: ChangeDetectorRef, private dateFormatter: MWDateFormatter) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  reinit() {

    this.datatable
      ?.clear(this.datatable?.datatable!);

  }

  setup() {

    if (!this.configuration?.CTDatatableConfiguration) {

      this.configuration?.setCTDatatableConfiguration(CTDatatableConfiguration.create());

    }

    this.configuration
      ?.CTDatatableConfiguration
      ?.setPaginator(false)
      ?.setCounterConfiguration(null)
      ?.setRowHover(true)
      ?.setClearEnabled(true)
      ?.setScrollHeight("calc(100vh - 250px)")
      ?.setPageSize(100)
      ?.setScrollable(true)
      ?.setVirtualScroll(true)
      ?.setLazy(true);

    if (!this.configuration?.CTDatatableConfiguration?.virtualScrollItemSize) {

      this.configuration?.CTDatatableConfiguration?.setVirtualScrollItemSize(46);

    }

    this.configuration
      ?.CTDatatableConfiguration
      ?.setFetchDataCaller(async (parameter: CTDatatableParameter) => this.fetch(parameter));

    this.cdr.detectChanges();

  }

  onResetPagination() {

    this.configuration?.setPageInfo(null);

  }

  private fetch(parameter: CTDatatableParameter): Observable<any> {

    return new Observable(observer => {

      const fetchData = async () => {

        try {

          const { event } = parameter;
          const { globalFilter, sortField, sortOrder, filters } = event as any;

          const pageInfo = this.configuration?.pageInfo;

          // Verifica se non c'è una pagina successiva
          if (pageInfo && !pageInfo.hasNextPage) {

            observer.complete();
            return;

          }

          const variables: ApolloVariableClass = ApolloVariableClass.create()
            .setFirst(this.configuration?.CTDatatableConfiguration?.pageSize ?? 10)
            .setAfter(this.configuration?.pageInfo?.endCursor ?? null);

          if (globalFilter) variables.setSearchText(globalFilter);

          if (sortField) {

            const sortObject = this.createNestedObject(sortField, sortOrder === 1 ? "ASC" : "DESC");
            variables.setOrder([sortObject]);

          }

          const enabledFilters = Object
            .keys(filters ?? {})
            .filter(key => {

              if (key === "global") return false;
              const filter = Array.isArray(filters[key]) ? filters[key][0] : filters[key];

              return !!filter.value;

            });

          if (enabledFilters.length) {

            const whereAndFilters = enabledFilters
              .map(key => {

                const filter: FilterMetadata = Array.isArray(filters[key]) ? filters[key][0] : filters[key];
                let value = filter.value;

                if (typeof value === "number") { // Se filter.value è un numero, aggiorna matchMode di conseguenza

                  filter.matchMode = "eq"; // O l'operatore che desideri utilizzare per confrontare numeri

                } else if (value instanceof Date) {

                  filter.matchMode = "gt";

                }

                return this.createNestedObject(key, { [filter.matchMode!]: value });

              });

            variables.setWhereAndFilters(whereAndFilters);

          }

          const caller = await this.configuration?.fetchDataCaller!(variables);

          if (!caller) {

            observer.complete();
            return;

          }

          caller
            .subscribe(response => {

              if (!this.configuration?.fetchResponseDataMapper) return;

              const data: ApolloResultDataList<T> = this.configuration.fetchResponseDataMapper(response);
              this.configuration?.setPageInfo(data.pageInfo);

              observer.next({ items: data.nodes, count: data?.totalCount ?? 0 });
              observer.complete();

            });

        } catch (error) {

          observer.error(error);

        }

      };

      setTimeout(() => fetchData().then(), Math.random() * 1000 + 250); // con graphql il lazy loading e il virtual scroll hanno comunque bisogno di un minimo di delay

    });

  }

  private createNestedObject(key: any, value: any) {

    const keys = key.split("."); // Dividi la chiave usando il punto come separatore
    let nestedObject = { [keys.pop()]: value };

    for (let i = keys.length - 1; i >= 0; i--) nestedObject = { [keys[i]]: nestedObject };  // Crea gli oggetti annidati a partire dal fondo dell'array 'keys'

    return nestedObject;
  }

}
