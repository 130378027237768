import { Component, EventEmitter, Inject, Input, Optional, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ShipmentAddressFormConfiguration } from "./shipment-address-form.configuration";
import {
  MAT_LEGACY_DIALOG_DATA,
  MatLegacyDialogRef
} from "@angular/material/legacy-dialog";
import { ConfirmService } from "../confirm/confirm.service";
import { IndirizzoSpedizioneModel } from "../../core/classes/spedizione/indirizzo";
import {
  CustomerAddressSelectControlComponent
} from "./customer-address-select-control/customer-address-select-control.component";
import { AddressDataFormComponent } from "./address-data-form/address-data-form.component";
import { RecipientContactDataFormComponent } from "./recipient-contact-data-form/recipient-contact-data-form.component";
import { ShipmentAddressFormService } from "./shipment-address-form.service";
import { CustomerLookupControlComponent } from "./customer-lookup-control/customer-lookup-control.component";
import { CtSelectControlValue } from "@ctsolution/ct-framework";
import { MwLookupControlService } from "../mw-lookup-control/mw-lookup-control.service";

@Component({
  selector: "app-shipment-address-form",
  templateUrl: "./shipment-address-form.component.html",
  styles: [
    `::ng-deep .shipment-address-form-container {
      h2 {
        font-size: 16px
      }

      .shipment-address-form-actions {
        display: flex;
        justify-content: end;
      }
    }`
  ],
  providers: [MwLookupControlService]
})
export class ShipmentAddressFormComponent {

  @ViewChild(RecipientContactDataFormComponent) contactDataFormComponent: RecipientContactDataFormComponent | null = null;
  @ViewChild(AddressDataFormComponent) addressDataFormComponent: AddressDataFormComponent | null = null;
  @ViewChild("customerAddressSelectControl") customerAddressSelectControl: CustomerAddressSelectControlComponent | null = null;
  @ViewChild("customerLookupControl") customerLookupControl: CustomerLookupControlComponent | null = null;

  @Output() onUpdate: EventEmitter<IndirizzoSpedizioneModel | null> = new EventEmitter<IndirizzoSpedizioneModel | null>();
  @Input() configuration: ShipmentAddressFormConfiguration | null = null;

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private confirmService: ConfirmService,
    private shipmentAddressFormService: ShipmentAddressFormService,
    @Optional() public dialogRef: MatLegacyDialogRef<ShipmentAddressFormComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) dialogConfiguration: ShipmentAddressFormConfiguration) {

    this.form = this.formBuilder
      .group({
        customer: new FormControl(null, Validators.required),
        customerSuggestion: new FormControl(null),
        isPrincipale: new FormControl(false)
      });

    if (dialogConfiguration) {

      this.configuration = dialogConfiguration;

      const editMode = !!dialogConfiguration.values?.cliente?.id;

      if (editMode) {

        this.form.get("customer")?.disable();

      }

    }

  }

  ngAfterViewInit() {

    this.setupForm();

    this.configuration
      ?.submitButton
      ?.setAction(() => this.submit());

    if (this.configuration?.customerAddressSuggestionsEnabled) {

      this.form
        ?.get("customer")
        ?.valueChanges
        .subscribe(response => {

          const customerValue = response?.value;

          this.customerAddressSelectControl?.setup(customerValue);

          if (!customerValue?.id) {

            this.form.get("customerSuggestion")?.setValue(null);

            this.customerAddressSelectControl
              ?.lookupControl
              ?.setupFetcher({ values: [] });

          }

        });

    }

    if (!this.configuration?.customerSelectionEnabled) {

      this.form.removeControl("customer");

    }

    this.form
      ?.get("customerSuggestion")
      ?.valueChanges
      .subscribe(value => {

        if (value) this.setupValues(value);

      });

    this.setupValues();

  }

  setupForm() {

    this.form
      .addControl("indirizzo", this.addressDataFormComponent?.form);

    if (this.configuration?.customerContactInformationEnabled) {

      this.form
        .addControl("contatto", this.contactDataFormComponent?.form);

    }

  }

  async updateCustomerSuggestions() {

    const control = this.form.get("customer");
    this.customerAddressSelectControl?.setup(control?.value);

  }

  get saveAddressButtonEnabled() {

    if (!this.configuration?.savingActionEnabled) return false;

    const customerSuggestionControl = this.form.get("customerSuggestion");
    const customerSuggestionValue = customerSuggestionControl?.value;

    if (this.form.valid) {

      if (!customerSuggestionValue) {

        return true;

      } else {

        const { contatto, indirizzo } = this.form.value;

        const contattoDiverso =
          contatto?.nomeDestinatario?.toLowerCase() !== customerSuggestionValue.nomeDestinatario?.toLowerCase() ||
          contatto?.emailDestinatario?.toLowerCase() !== customerSuggestionValue.emailDestinatario?.toLowerCase() ||
          contatto?.telefonoDestinatario !== customerSuggestionValue.telefonoDestinatario;

        const indirizzoDiverso =
          indirizzo.via?.toLowerCase() !== customerSuggestionValue.indirizzo.via?.toLowerCase() ||
          indirizzo.cap?.toLowerCase() !== customerSuggestionValue.indirizzo.cap?.toLowerCase() ||
          indirizzo.provincia?.toLowerCase() !== customerSuggestionValue.indirizzo.provincia?.toLowerCase() ||
          indirizzo.comune?.toLowerCase() !== customerSuggestionValue.indirizzo.comune?.toLowerCase() ||
          indirizzo.stato?.value?.nome?.toLowerCase() !== customerSuggestionValue.indirizzo.stato?.nome?.toLowerCase();

        return contattoDiverso || indirizzoDiverso;

      }

    }

    return this.form.valid;

  }

  setupValues(values: IndirizzoSpedizioneModel | null = null) {

    if (values) {

      this.configuration
        ?.setValues(values);

    }

    if (this.configuration?.values?.cliente) {

      const cliente = this.configuration?.values?.cliente;

      const customerValue = CtSelectControlValue
        .create()
        .setLabel(cliente?.nomeRiferimento)
        .setValue(cliente);

      this.customerLookupControl
        ?.configuration
        ?.CTControl
        .setValue(customerValue);

    }

    if (this.configuration?.values) {

      this.contactDataFormComponent
        ?.setupValues(this.configuration?.values);

      if (this.configuration.values.indirizzo) {

        this.addressDataFormComponent
          ?.setupValues(this.configuration.values.indirizzo);

      }

    }

    if (this.configuration?.values?.isPrincipale) {

      this.form.get("isPrincipale")?.setValue(this.configuration?.values?.isPrincipale);

    }

  }

  async submit() {

    this.form.markAllAsTouched();

    if (this.form.invalid) return;

    const value = this.form.getRawValue();

    const response = await this.shipmentAddressFormService.createOrUpdate(value, this.configuration?.values?.id);

    if (this.dialogRef) {

      this.dialogRef?.close(true);

    } else {

      this.onUpdate.emit(response);

    }

  }

  close() {

    if (this.form?.touched) {

      const dialogRef = this.confirmService.open();

      dialogRef
        .afterClosed()
        .subscribe((result?: boolean) => {

          if (result) this.dialogRef.close();

        });

      return;

    }

    this.dialogRef.close();

  }

}
