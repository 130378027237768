import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardComponent } from "./dashboard.component";
import { CtButtonModule, CtCardModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDividerModule } from "@angular/material/divider";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { NgApexchartsModule } from "ng-apexcharts";
import { TranslateModule } from "@ngx-translate/core";
import { MeteoWidgetModule } from "./meteo-widget/meteo-widget.module";
import { MatIconModule } from "@angular/material/icon";
import { CustomersCounterWidgetModule } from "./customers-counter-widget/customers-counter-widget.module";
import { StocksCounterWidgetModule } from "./stocks-counter-widget/stocks-counter-widget.module";
import { NotificationsWidgetModule } from "./notifications-widget/notifications-widget.module";
import {WineTypesWidgetModule} from "./wine-types-widget/wine-types-widget.module";

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    CtButtonModule,
    CtCardModule,
    FlexLayoutModule,
    MatDividerModule,
    MatCardModule,
    NgApexchartsModule,
    TranslateModule,
    MeteoWidgetModule,
    MatIconModule,
    CustomersCounterWidgetModule,
    StocksCounterWidgetModule,
    NotificationsWidgetModule,
    WineTypesWidgetModule
  ]
})
export class DashboardModule {
}
