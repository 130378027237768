import { Injectable } from "@angular/core";
import { BaseController } from "../../core/controllers/base.controller";
import { ModelDetailConfiguration } from "../../components/model-detail/model-detail.configuration";
import { ModelDetailService } from "../../components/model-detail/model-detail.service";
import {
  SectionDetailDataConfiguration
} from "../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { MWDateFormatter } from "../../core/lib/date-format.service";
import { QueriesService } from "../../core/lib/queries.service";
import { TipoClienteEnum } from "../../core/enum/tipo-cliente.enum";
import { ClienteModel } from "../../core/classes/cliente";
import { ClienteApolloResult } from "../../core/interfaces/apollo/cliente.apollo-result";
import { CustomerFormConfiguration } from "./customer-form/customer-form.configuration";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CustomerFormComponent } from "./customer-form/customer-form.component";
import { ShipmentAddressFormService } from "../../components/shipment-address-form/shipment-address-form.service";
import { DetailDataConfiguration } from "../../components/model-detail/section-detail-data/detail-data.configuration";
import { iconsBasePath } from "../../core/constants";

@Injectable({
  providedIn: "root"
})
export class CustomerService {

  constructor(
    private detailDataService: ModelDetailService,
    private dialog: MatLegacyDialog,
    private baseController: BaseController,
    private shipmentAddressFormService: ShipmentAddressFormService,
    private _queries: QueriesService
  ) {
  }

  private async openFormDialog(tipoCliente: TipoClienteEnum, value: ClienteModel | null, params?: {
    width?: number | string;
    maxWidth?: number | string;
  }) {

    const data: CustomerFormConfiguration = CustomerFormConfiguration
      .create()
      .setValues(value)
      .setTipoCliente(tipoCliente);

    return this.dialog
      .open(CustomerFormComponent, {
        data,
        panelClass: "entity-form-dialog",
        disableClose: true,
        width: <string | undefined>params?.width,
        maxWidth: <string | undefined>params?.maxWidth
      });

  }

  async setupCustomer(tipoCliente: TipoClienteEnum, dataSource: ClienteModel | null = null): Promise<boolean | null | undefined> {

    if (dataSource) {

      dataSource = await this.getCustomerData(dataSource.id);

      if (!dataSource) return null;

      dataSource = ClienteModel.create(dataSource);

    }

    return this.createOrUpdateByEntityForm(tipoCliente, dataSource);

  }

  async createOrUpdateByEntityForm(tipoCliente: TipoClienteEnum, dataSource: ClienteModel | null): Promise<boolean | null | undefined> {

    return new Promise<boolean | null | undefined>(async (resolve) => {

      const dialog = await this.openFormDialog(tipoCliente, dataSource, { maxWidth: "840px" });

      dialog
        .afterClosed()
        .subscribe(async (result: boolean | null | undefined) => resolve(result));

    });

  }

  async getCustomerData(customerId: string | null): Promise<ClienteModel | null> {

    if (!customerId) return null;

    const query = await this._queries.entities.cliente();

    if (!query) return null;

    const caller = await this.baseController.get<ClienteApolloResult>(customerId, query);

    if (!caller) return null;

    try {

      const result = await new Promise<ClienteApolloResult>((resolve, reject) => {

        caller
          .subscribe({
            next: (data) => resolve(data),
            error: (error) => reject(error)
          });

      });

      return result?.cliente ?? null;

    } catch (error) {

      // Gestire eventuali errori qui
      console.error(error);
      return null;

    }

  }

  async getCustomerDialogDetail(depositId: string) {

    const model = await this.getCustomerDataModelDetailDialog(depositId);

    if (!model) return;

    this.detailDataService.open(model, { width: "500px" });

  }

  private async getCustomerDataModelDetailDialog(customerId: string | null): Promise<ModelDetailConfiguration | null> {

    const model = await this.getCustomerData(customerId);

    if (!model) return null;

    const modelDetail: ModelDetailConfiguration = ModelDetailConfiguration
      .create()
      .setTitle("Dettaglio cliente");

    if (model.nome || model.cognome || model.dataDiNascita != null) {

      const customerDetailSection = this.getPrivateCustomerDetailSection(model);

      modelDetail
        .addSection(customerDetailSection);

    }

    if (model.ragioneSociale || model.partitaIva != null) {

      const businessDetailSection = this.getBusinessCustomerDetailSection(model);

      modelDetail
        .addSection(businessDetailSection);

    }

    const generalDataSection = this.getGeneralDataSection(model);

    if (generalDataSection) {

      modelDetail
        .addSection(generalDataSection);

    }

    const [firstSection] = modelDetail.sections;
    const hasOnlyOneData = firstSection.dataList.length === 1;

    if (hasOnlyOneData) {

      const [firstData] = firstSection.dataList;
      firstData.setFxFlex(100).setFxLayout(null);

    }

    const addressDataSection = await this.getAddressDataSection(model);

    if (addressDataSection.dataList.length) {

      modelDetail
        .addSection(addressDataSection);

    }

    return modelDetail;

  }

  private getPrivateCustomerDetailSection(model: ClienteModel) {

    const section = SectionDetailDataConfiguration
      .create();

    if (model.nome) {

      const nameDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.name", model?.nome);

      section
        .addDetailData(nameDetailData);

    }

    if (model.cognome) {

      const surnameDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.surname", model?.cognome);

      section
        .addDetailData(surnameDetailData);

    }

    if (model.dataDiNascita) {

      const birthdayDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.birthday", MWDateFormatter.create().formatDateToString(model?.dataDiNascita));

      section
        .addDetailData(birthdayDetailData);

    }

    return section;

  }

  private getBusinessCustomerDetailSection(model: ClienteModel) {

    const section = SectionDetailDataConfiguration
      .create();

    if (model.ragioneSociale) {

      const ragioneSocialeDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.company_name", model?.ragioneSociale);

      section
        .addDetailData(ragioneSocialeDetailData);

    }

    if (model.partitaIva) {

      const partitaIvaDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.vat_number", model?.partitaIva);

      section
        .addDetailData(partitaIvaDetailData);

    }

    return section;

  }

  getGeneralDataSection(model: ClienteModel): SectionDetailDataConfiguration | null {

    const section = SectionDetailDataConfiguration
      .create("Informazioni di Contatto");

    if (model.codiceFiscale) {

      const codiceFiscaleDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.fiscal_code", model?.codiceFiscale);

      section
        .addDetailData(codiceFiscaleDetailData);

    }

    if (model.email) {

      const emailDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.email", model?.email);

      section
        .addDetailData(emailDetailData);

    }

    if (model.telefono) {

      const telefonoDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel("CT_GENERAL.phone_number", model?.telefono);

      section
        .addDetailData(telefonoDetailData);

    }

    if (!model.codiceFiscale && !model.email && !model.telefono) {

      return null;

    }

    return section;

  }

  async getAddressDataSection(model: ClienteModel) {

    const section = SectionDetailDataConfiguration
      .create("Indirizzi destinatari");

    const availableAddresses = await this.shipmentAddressFormService.getCustomerAddresses(model.id);

    availableAddresses
      ?.forEach(address => {

        const addressData = DetailDataConfiguration
          .create()
          .setCTCardConfigurationFromDetailDataModel(
            address.nomeDestinatario ?? "",
            `${address?.indirizzo?.via}, ${address?.indirizzo?.cap}, ${address?.indirizzo?.comune}, ${address?.indirizzo?.stato?.nome}`,
            iconsBasePath + "customer.svg")
          .setFxFlex(100)
          .setFxLayout(null);

        section
          .addDetailData(addressData);

      });

    return section;

  }

}
