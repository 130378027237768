import {
  CTDatatableFilterConfiguration,
  CTDatatableHeaderCellConfiguration,
  CTDatatatableHeaderCellIconConfiguration,
  CTDatatatableHeaderCellIconType
} from "@ctsolution/ct-framework";
import { iconsBasePath } from "../../core/constants";
import { TranslateService } from "@ngx-translate/core";

export const destinazioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("depositante.nomeRiferimento")
  .setLabel(translate.instant("CT_GENERAL.customer"))
  .setInlineStyle("min-width: 200px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("CT_DATATABLE.SHIPMENT.DepositorReferenceName")
      .setShowMenu(true));

export const depositoCodiceHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("deposito.codice")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.Code"))
  .setInlineStyle("min-width: 100px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.CodeSearch"))
      .setShowMenu(true));

export const quantitaHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantita")
  .setLabel(translate.instant("CT_GENERAL.quantity"))
  .setInlineStyle("width: 300px;")
  .setSortable(true)
  .setIcon(
    CTDatatatableHeaderCellIconConfiguration
      .create()
      .setIcon(`${iconsBasePath}pallet.svg`)
      .setType(CTDatatatableHeaderCellIconType.image)
  )
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setType("numeric")
      .setPlaceHolder(translate.instant("CT_GENERAL.quantitySearch"))
      .setShowMenu(true));

export const quantitaInLavorazioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantitaInLavorazioneHeader")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.InProgress"))
  .setInlineStyle("width: 250px;")
  .setSortable(true)
  .setIcon(
    CTDatatatableHeaderCellIconConfiguration
      .create()
      .setIcon(`${iconsBasePath}pallet-on-work.svg`)
      .setType(CTDatatatableHeaderCellIconType.image)
  )
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setType("numeric")
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.InProgressSearch"))
      .setShowMenu(true));

export const vinoDescrizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("vino.descrizione")
  .setLabel(translate.instant("CT_GENERAL.item-code"))
  .setSortable(true)
  .setInlineStyle("min-width:450px")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_GENERAL.item-codeSearch"))
      .setShowMenu(true));

export const quantitaTotaleHeader = (translate : TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantita")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.BottleTotal"))
  .setInlineStyle("width: 300px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.BottleTotalSearch"))
      .setType('numeric')
      .setShowMenu(true));

export const quantitaScatoleHeader = (translate : TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantitaScatole")
  .setLabel(translate.instant("CT_GENERAL.boxes"))
  .setInlineStyle("width: 250px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_GENERAL.boxesSearch"))
      .setType('numeric')
      .setShowMenu(true));

export const quantitaBottiglieSfuseHeader = (translate : TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantitaBottiglieSfuse")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.BulkQuantity"))
  .setInlineStyle("width: 200px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.BulkQuantitySearch"))
      .setType('numeric')
      .setShowMenu(true));

export const contenutoHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("contenuti.vino.descrizioneBreve")
  .setInlineStyle("min-width: 500px;")
  .setLabel(translate.instant("CT_GENERAL.content"))
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_GENERAL.contentSearch"))
      .setShowMenu(true));

export const vinoProduttoreHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("vino.produttore")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.Maker"))
  .setInlineStyle("min-width: 180px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.MakerSearch"))
      .setShowMenu(true));
