import { Component, ViewChild } from "@angular/core";
import { QueriesService } from "../../../../../core/lib/queries.service";
import { DenominazioniApolloResult } from "../../../../../core/interfaces/apollo/denominazioni.apollo-result";
import { GenericReadModel } from "../../../../../core/classes/generic-read.model";
import { MwLookupControlComponent } from "../../../../../components/mw-lookup-control/mw-lookup-control.component";
import {
  MwLookupControlConfiguration
} from "../../../../../components/mw-lookup-control/mw-lookup-control.configuration";

@Component({
  selector: "mw-denominazione-control",
  template: `
    <mw-lookup-control
      #lookupControl
      [configuration]="configuration"></mw-lookup-control>`
})
export class DenominazioneControlComponent {

  @ViewChild("lookupControl") lookupControl: MwLookupControlComponent<GenericReadModel, DenominazioniApolloResult> | null = null;

  configuration: MwLookupControlConfiguration<GenericReadModel, DenominazioniApolloResult> = MwLookupControlConfiguration
    .create<GenericReadModel, DenominazioniApolloResult>()
    .setQuery(this._queries.entities.denominazioni)
    .setResponseMapper(value => value.denominazioni)
    .setOptionDescriptionFn(value => value?.nome ?? "Unknown")
    .setAutoInitializeValueOptions(false)
    .setOrder([{ nome: "ASC" }])
    .configureControl(control => {

      control
        ?.setLabel("Denominazione");

    });

  constructor(private _queries: QueriesService) {
  }

}
