import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileComponent } from "./profile.component";
import { RouterModule } from "@angular/router";

import { FlexLayoutModule } from "@angular/flex-layout";
import { CtButtonModule, CtFormModule } from "@ctsolution/ct-framework";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { AddressUpdateDialogModule } from "./address-update-dialog/address-update-dialog.module";

@NgModule({
  declarations: [
    ProfileComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    CtButtonModule,
    CtFormModule,
    AddressUpdateDialogModule,
    RouterModule.forChild([
      {
        path: "",
        component: ProfileComponent,
        data: {
          title: "Profilo",
          urls: []
        }
      }
    ]),
    MatLegacyButtonModule,
    TranslateModule,
    MatIconModule
  ]
})
export class ProfileModule {
}


