import { ClienteModel } from "../cliente";
import { GiacenzaVinoModel } from "../../interfaces/giacenza-vino.model";

export class GiacenzaPalletModel {

  id: string | null = null;
  depositante: ClienteModel | null = null;
  contenuti: Array<GiacenzaVinoModel> | null = null;
  deposito: { id: string | null; codice: string | null; } | null = null;
  quantita: number | null = null;

}
