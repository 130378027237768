<mw-datatable
  #table
  class="product-datatable-container"
  [configuration]="listConfiguration"
  (onSave)="onSave($event)"
  (onDetail)="detail($event)">

  <ng-template #body let-value="value">
    <td>
      {{ value?.descrizione ?? '' }}
    </td>
    <td>
      {{ value?.tipoVino?.nome ?? '' }}
    </td>
    <td>
      {{ value?.denominazione?.nome ?? '' }}
    </td>
    <td>
      {{ value?.anno ?? '' }}
    </td>
    <td>
      {{ value?.menzione?.nome ?? '' }}
    </td>
    <td>
      {{ value?.produttore ?? '' }}
    </td>
    <td>
      {{ value?.tipologia?.nome ?? '' }}
    </td>
    <td>
      {{ value?.regione?.stato?.nome ?? '' }}
    </td>
    <td>
      {{ value?.gradazione ?? '' }}
    </td>
  </ng-template>

</mw-datatable>
