import { Injectable } from "@angular/core";
import { BaseController } from "../../core/controllers/base.controller";
import {
  SectionDetailDataConfiguration
} from "../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { ModelDetailService } from "../../components/model-detail/model-detail.service";
import { ModelDetailConfiguration } from "src/app/components/model-detail/model-detail.configuration";
import { QueriesService } from "../../core/lib/queries.service";
import { MWDateFormatter } from "../../core/lib/date-format.service";
import { DepositoApolloResult } from "../../core/interfaces/apollo/deposito.apollo-result";
import { DepositoModel } from "../../core/classes/deposito/deposito";
import { iconsBasePath } from "../../core/constants";
import { ColloStoccaggio } from "../../core/classes/deposito/collo-stoccaggio";
import { ColloRitiro } from "../../core/classes/deposito/collo-ritiro";
import { ProdottiModel } from "../../core/classes/prodotti";
import { DetailDataConfiguration } from "../../components/model-detail/section-detail-data/detail-data.configuration";
import { ModalitaStoccaggio } from "../../core/enum/modalita-stoccaggio.enum";
import { CtCardConfiguration } from "@ctsolution/ct-framework";
import { StatoDepositoEnum } from "../../core/enum/stato-deposito.enum";

@Injectable()
export class DepositService {

  statiDeposito = Object.keys(StatoDepositoEnum).map(key => (<any>StatoDepositoEnum)[key]);

  constructor(
    private baseController: BaseController,
    private detailDataService: ModelDetailService,
    private dateFormatter: MWDateFormatter,
    private _queries: QueriesService) {
  }

  async getDepositData(depositId: string | null): Promise<DepositoModel | null> {

    if (!depositId) return null;

    const query = await this._queries.entities.deposito();

    if (!query) return null;

    const caller = await this.baseController.get<DepositoApolloResult>(depositId, query);

    if (!caller) return null;

    try {

      const result = await new Promise<DepositoApolloResult>((resolve, reject) => {

        caller
          .subscribe({
            next: (data) => resolve(data),
            error: (error) => reject(error)
          });

      });

      return result?.deposito ?? null;

    } catch (error) {

      // Gestire eventuali errori qui
      console.error(error);
      return null;

    }

  }

  private getDettaglioDepositoModelDetailSection(value: DepositoModel) {

    const section: SectionDetailDataConfiguration = SectionDetailDataConfiguration
      .create()
      .enableEndSectionDivider(true);

    //#region set data
    const dateDetailData = DetailDataConfiguration
      .create()
      .setCTCardConfigurationFromDetailDataModel(
        value.ritiro?.dataPickup ? "Data Pick-up" : value.dataPresuntaRicevimentoMerce ? "Data Check-in" : "Not Available",
        this.dateFormatter.formatDateToString(value.ritiro?.dataPickup ?? value.dataPresuntaRicevimentoMerce ?? null),
        iconsBasePath + "calendar.svg")
      .setFxFlex(50)
      .setFxLayout(null);

    section
      .addDetailData(dateDetailData);

    //#endregion set data

    //#region set peso

    if (value.ritiro?.totalePesoTrasporto) {

      const pesoDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel(
          "Peso Totale",
          `${value.ritiro?.totalePesoTrasporto} kg`,
          iconsBasePath + "kg.svg")
        .setFxFlex(50)
        .setFxLayout(null);

      section
        .addDetailData(pesoDetailData);

    }

    //#endregion set peso

    //#region set descrizione

    if (value?.descrizione) {

      const descrizioneDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel(
          "Descrizione",
          value?.descrizione,
          iconsBasePath + "note.svg")
        .setFxFlex(40)
        .setFxLayout(null);

      section
        .addDetailData(descrizioneDetailData);

    }

    //#endregion set descrizione

    //#region set note

    if (value?.note) {

      const noteDetailData = DetailDataConfiguration
        .create()
        .setCTCardConfigurationFromDetailDataModel(
          "Note",
          value?.note,
          iconsBasePath + "note.svg")
        .setFxFlex(60)
        .setFxLayout(null);

      section
        .addDetailData(noteDetailData);

    }

    //#endregion set note

    return section;

  }

  private getColliRitiroModelDetailSections(value: DepositoModel) {

    const sections: SectionDetailDataConfiguration[] = new Array<SectionDetailDataConfiguration>(
      SectionDetailDataConfiguration
        .create()
        .addDetailData(
          DetailDataConfiguration
            .create()
            .setCTCardConfiguration(
              CtCardConfiguration
                .create()
                .setTitle("Imballi spedizione")
                .setDescription("Dimensioni e peso degli imballi utilizzati per la spedizione")
            )
            .setFxLayout(null)
        ),
      ...this.getColliRitiroSections(value)
    );

    return sections;

  }

  private getColliRitiroSections(value: DepositoModel) {

    return value
      .colliRitiro
      .map(collo => this.getColloRitiroSection(collo).enableEndSectionDivider(true));

  }

  private getColliStoccaggioModelDetailSections(value: DepositoModel, stoccaggioPallet: boolean = false) {

    const colliStoccaggioModelDetailSections: SectionDetailDataConfiguration[] = new Array<SectionDetailDataConfiguration>();

    (value
      ?.colliStoccaggio ?? [])
      .forEach((collo: ColloStoccaggio, index: number) => {

        //#region modalita stoccaggio

        const colloStoccaggioDescriptionSection: SectionDetailDataConfiguration = this.getColloStoccaggioDescriptionSection(collo, index + 1);

        colliStoccaggioModelDetailSections
          .push(colloStoccaggioDescriptionSection);

        //#endregion modalita stoccaggio

        if (stoccaggioPallet) {

          //#region collo ritiro

          const colloRitiroSection = this.getColliRitiroSections(value)[index];

          if (colloRitiroSection) {

            colliStoccaggioModelDetailSections
              .push(colloRitiroSection);

          }

        }

        //#endregion collo ritiro

        //#region prodotti

        const prodottiSections = this.getProdottiSections(collo.prodotti);

        colliStoccaggioModelDetailSections
          .push(...prodottiSections);

        //#endregion prodotti

      });

    return colliStoccaggioModelDetailSections;

  }

  private getColloStoccaggioDescriptionSection(collo: ColloStoccaggio | null, index: number): SectionDetailDataConfiguration {

    const section: SectionDetailDataConfiguration = SectionDetailDataConfiguration.create();

    const detailData: DetailDataConfiguration = DetailDataConfiguration.create();

    const colloQuantita = collo?.quantita ?? -1;

    const { quantitaLabel, quantitaSuffixLabel } = {
      quantitaLabel: (colloQuantita >= 1 ? `${colloQuantita} ` : ""),
      quantitaSuffixLabel: (colloQuantita > 1 ? "e" : "a")
    };

    switch (collo?.modalitaStoccaggio) {

      case ModalitaStoccaggio.Pallet:
        detailData
          .setCTCardConfigurationFromDetailDataModel(quantitaLabel + "Pallet", "Questo collo è stato depositato in pallet"); // , iconsBasePath + "pallet.svg"
        break;
      case ModalitaStoccaggio.Scatola:
        detailData
          .setCTCardConfigurationFromDetailDataModel(quantitaLabel + `Scatol${quantitaSuffixLabel}`, "Questo collo è stato depositato in scatole"); // , iconsBasePath + "box.svg"
        break;
      case ModalitaStoccaggio.Bottiglia:
        detailData
          .setCTCardConfigurationFromDetailDataModel(quantitaLabel + `Bottigli${quantitaSuffixLabel}`, "Questo collo è stato depositato in bottiglie"); // , iconsBasePath + "bottles.svg"
        break;
    }

    detailData
      .setFxLayout(null);

    section
      .addDetailData(detailData);

    return section;

  }

  private getColloRitiroSection(colloRitiro: ColloRitiro) {

    const section: SectionDetailDataConfiguration = SectionDetailDataConfiguration.create();

    const colloRitiroCardConfiguration = (title: string, description: string): DetailDataConfiguration => DetailDataConfiguration
      .create()
      .setCTCardConfigurationFromDetailDataModel(title, description)
      .setFxFlex(20)
      .setFxLayout(null);

    if (colloRitiro.altezza) {

      const altezzaDetailData = colloRitiroCardConfiguration("Altezza", `${colloRitiro.altezza} cm`);

      section
        .addDetailData(altezzaDetailData);

    }

    if (colloRitiro.lunghezza) {

      const lunghezzaDetailData = colloRitiroCardConfiguration("Lunghezza", `${colloRitiro.lunghezza} cm`);

      section
        .addDetailData(lunghezzaDetailData);

    }

    if (colloRitiro.larghezza) {

      const larghezzaDetailData = colloRitiroCardConfiguration("Larghezza", `${colloRitiro.larghezza} cm`);

      section
        .addDetailData(larghezzaDetailData);

    }

    if (colloRitiro.pesoKg) {

      const pesoDetailData = colloRitiroCardConfiguration("Peso", `${colloRitiro.pesoKg} kg`);

      section
        .addDetailData(pesoDetailData);

    }

    if (colloRitiro.quantita) {

      const quantitaDetailData = colloRitiroCardConfiguration("Quantità", `${colloRitiro.quantita}`);

      section
        .addDetailData(quantitaDetailData);

    }

    return section;

  }

  private getProdottiSections(prodotti: Array<ProdottiModel> | null = []): SectionDetailDataConfiguration[] {

    const prodottiSections: SectionDetailDataConfiguration[] = [];

    prodotti
      ?.forEach(prodotto => {

        prodottiSections
          .push(this.detailDataService.getProdottoSection(prodotto).enableEndSectionDivider(true));

      });

    return prodottiSections;

  }

  async getDepositPackagingCollectionDetail(depositId: string | null): Promise<ModelDetailConfiguration | null> {

    const model = await this.getDepositData(depositId);

    if (!model) return null;

    const modelDetail: ModelDetailConfiguration = ModelDetailConfiguration
      .create()
      .setTitle("Dettaglio ritiro");

    const stoccaggioPallet = model?.colliStoccaggio.every(collo => collo.modalitaStoccaggio === ModalitaStoccaggio.Pallet);

    if (stoccaggioPallet) {

      this.showDetail(depositId!);
      return null;

    } else {

      const colliRitiro: SectionDetailDataConfiguration[] = this.getColliRitiroModelDetailSections(model);

      modelDetail
        .addSections(colliRitiro);

    }

    return modelDetail;

  }

  async getDepositModelDetail(depositId: string | null): Promise<ModelDetailConfiguration | null> {

    const model = await this.getDepositData(depositId);

    if (!model) return null;

    const modelDetail: ModelDetailConfiguration = ModelDetailConfiguration
      .create()
      .setTitle("Dettaglio deposito");

    //#region set dettaglio deposito section

    const dettaglioDepositoSection = this.getDettaglioDepositoModelDetailSection(model);

    //#endregion set dettaglio deposito section

    //#region set colli stoccaggio deposito section

    const stoccaggioPallet = model?.colliStoccaggio.every(collo => collo.modalitaStoccaggio === ModalitaStoccaggio.Pallet);

    const colliStoccaggioDepositoSection: SectionDetailDataConfiguration[] = this.getColliStoccaggioModelDetailSections(model, stoccaggioPallet);

    //#endregion set colli stoccaggio deposito section

    modelDetail
      .addSection(dettaglioDepositoSection)
      .addSections(colliStoccaggioDepositoSection);

    return modelDetail;

  }

  showDetail(depositId: string) {

    this.getDepositModelDetail(depositId)
      .then(result => {

        if (!result) return;

        this.detailDataService.open(result);

      });

  }

  showPackagingCollection(depositId: string) {

    this.getDepositPackagingCollectionDetail(depositId)
      .then(result => {

        if (!result) return;

        this.detailDataService.open(result, { minWidth: "40vw" });

      });

  }

}
