import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { PalletStockDatatableComponent } from "./pallet-stock-datatable/pallet-stock-datatable.component";
import { MwDatatableModule } from "../../../components/mw-datatable/mw-datatable.module";
import { PalletStockDatatableModule } from "./pallet-stock-datatable/pallet-stock-datatable.module";
import { ButtonModule } from "primeng/button";

@NgModule({
  imports: [
    CommonModule,
    PalletStockDatatableModule,
    RouterModule.forChild([
      {
        path: "",
        component: PalletStockDatatableComponent,
        data: {
          title: "Consultazione giacenze - Pallet",
          urls: []
        }
      }
    ]),
    MwDatatableModule,
    ButtonModule
  ]
})
export class PalletStockModule {
}
