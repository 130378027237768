import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DepositCreateWizardComponent} from "./deposit-create-wizard.component";
import {MatStepperModule} from "@angular/material/stepper";
import {RouterModule} from "@angular/router";
import {DepositCreateStep1Module} from "./deposit-create-step1/deposit-create-step1.module";
import {DepositCreateStep2Module} from "./deposit-create-step2/deposit-create-step2.module";
import {DepositCreateStep3Module} from "./deposit-create-step3/deposit-create-step3.module";
import {DepositCreateStep4Module} from "./deposit-create-step4/deposit-create-step4.module";
import {CtFormModule} from "@ctsolution/ct-framework";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
    declarations: [
        DepositCreateWizardComponent
    ],
    imports: [
        CommonModule,
        MatStepperModule,
        RouterModule.forChild([
            {
                path: "",
                component: DepositCreateWizardComponent,
                data: {
                    title: "Crea Deposito"
                }
            }
        ]),
        DepositCreateStep1Module,
        DepositCreateStep2Module,
        DepositCreateStep3Module,
        DepositCreateStep4Module,
        CtFormModule,
        MatLegacyButtonModule,
        TranslateModule
    ]
})
export class DepositCreateWizardModule {
}
