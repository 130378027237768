import { Injectable } from "@angular/core";
import { TypedDocumentNode } from "@apollo/client";
import { gql } from "apollo-angular";
import { HttpClient } from "@angular/common/http";
import { Query } from "../interfaces/query";

@Injectable({
  providedIn: "root"
})
export class QueriesService {

  private queries: Query[] = [];

  constructor(private http: HttpClient) {
  }

  public async getQuery(name: string): Promise<TypedDocumentNode | null> {

    if (!this.queries.length) {

      const jsonPath = "/assets/json/queries.json";

      try {

        const json = await this.http.get<any>(jsonPath).toPromise();
        this.queries = json.item;

      } catch (error) {

        console.error("Error loading JSON:", error);

      }

    }

    const query = this.queries.find((q) => q.name === name);

    if (query) {

      const graphql = query.request.body.graphql;
      return gql`${graphql.query}`;

    }

    return null;

  }

  entities = {

    clienti: () => this.getQuery("clienti"),

    cliente: () => this.getQuery("cliente"),

    indirizziSpedizione: () => this.getQuery("indirizziSpedizione"),

    indirizzoSpedizione: () => this.getQuery("indirizzoSpedizione"),

    spedizioni: () => this.getQuery("spedizioni"),

    spedizione: () => this.getQuery("spedizione"),

    vini: () => this.getQuery("vini"),

    vino: () => this.getQuery("vino"),

    depositi: () => this.getQuery("depositi"),

    deposito: () => this.getQuery("deposito"),

    giacenzaPallets: () => this.getQuery("giacenzaPallets"),

    giacenzaPallet: () => this.getQuery("giacenzaPallet"),

    tipiVino: () => this.getQuery("tipiVino"),

    confezioni: () => this.getQuery("confezioni"),

    depositante: () => this.getQuery("depositante"),

    giacenzaVini: () => this.getQuery("giacenzaVini"),

    denominazioni: () => this.getQuery("denominazioni"),

    menzioni: () => this.getQuery("menzioni"),

    menzioniEstero: () => this.getQuery("menzioniEstero"),

    formati: () => this.getQuery("formati"),

    metodologie: () => this.getQuery("metodologie"),

    tipologie: () => this.getQuery("tipologie"),

    stati: () => this.getQuery("stati"),

    statiPerVino: () => this.getQuery("statiPerVino"),

    regioni: () => this.getQuery("regioni"),

    snapshotGiacenze: () => this.getQuery("snapshotGiacenze"),

    movimentazioniGiacenza: () => this.getQuery("movimentazioniGiacenza"),

    presetImballi: () => this.getQuery("presetImballi"),

    notifiche: () => this.getQuery("notifiche"),

    totaliGiacenzaVini: () => this.getQuery("totaliGiacenzaVini")

  };

}
