import { Component, Inject, ViewChild } from "@angular/core";
import {
  ShipmentAddressFormConfiguration
} from "../../../../components/shipment-address-form/shipment-address-form.configuration";
import {
  ShipmentAddressFormComponent
} from "../../../../components/shipment-address-form/shipment-address-form.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { IndirizzoSpedizioneModel } from "../../../../core/classes/spedizione/indirizzo";
import { BaseRequestClass } from "../../../../core/classes/apollo/apollo.base.request";
import { DepositantiControllerRequest } from "../../../../core/controllers/controllers";
import { DepositoModel } from "../../../../core/classes/deposito/deposito";
import { ClienteModel } from "../../../../core/classes/cliente";
import { BaseController } from "../../../../core/controllers/base.controller";

@Component({
  selector: "app-address-update-dialog",
  templateUrl: "./address-update-dialog.component.html",
  styleUrls: ["./address-update-dialog.component.scss"]
})
export class AddressUpdateDialogComponent {

  @ViewChild("shipmentAddressFormComponent") shipmentAddressFormComponent: ShipmentAddressFormComponent | null = null;

  form: FormGroup;
  shipmentAddressFormConfiguration: ShipmentAddressFormConfiguration = ShipmentAddressFormConfiguration
    .create()
    .enableCustomerSelection(false)
    .enableCustomerContactInformation(false)
    .setTitle("Sede legale");

  constructor(private formBuilder: FormBuilder, @Inject(MAT_LEGACY_DIALOG_DATA) public model: ClienteModel | null, private baseController: BaseController) {

    this.form = this.formBuilder.group({});

    if (this.model) {

      this.shipmentAddressFormConfiguration
        .setValues({ indirizzo: this.model.indirizzo } as IndirizzoSpedizioneModel);

    }

  }

  ngAfterViewInit() {

    this.form
      .addControl("shipmentAddressData", this.shipmentAddressFormComponent?.form);

    this.shipmentAddressFormConfiguration
      .submitButton
      .setAction(() => this.onSave());

  }

  async onSave() {

    if (this.form.invalid) return;

    const value = this.form.value;

    let updateValue: any = Object.assign({}, this.model);

    const indirizzo = value?.shipmentAddressData?.indirizzo;

    if (indirizzo?.stato?.value) {

      indirizzo.statoId = indirizzo?.stato?.value?.id;

    }

    updateValue["indirizzo"] = indirizzo;

    if (updateValue.__typename) {

      updateValue["tipo"] = updateValue.__typename;

    }

    const parameter: BaseRequestClass = DepositantiControllerRequest()
      .setDataSource(updateValue);

    parameter
      .bypassIdSetting = true;

    parameter
      .forcePut = true;

    const caller = await this.baseController.executeDepositorEntityRequest<DepositoModel>(parameter);

    caller
      ?.subscribe(response => window.location.reload());

  }

}
