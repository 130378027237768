import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MwCardRadioGroupConfiguration
} from "../../../../../components/mw-card-radio-group/mw-card-radio-group.configuration";
import { TipoSpedizioneEnum } from "../../../../../core/enum/tipo-spedizione.enum";
import {
  MwCardRadioOptionConfiguration
} from "../../../../../components/mw-card-radio-group/mw-card-radio-option/mw-card-radio-option.configuration";
import { iconsBasePath } from "../../../../../core/constants";

const ShipmentTypeCardRadioOptionConfigurations: MwCardRadioOptionConfiguration<TipoSpedizioneEnum>[] = [
  MwCardRadioOptionConfiguration
    .create<TipoSpedizioneEnum>()
    .setImage(`${iconsBasePath}pallet.svg`)
    .setValue(TipoSpedizioneEnum.GiacenzaPallet)
    .setLabel(`Aggiungi pallet`),
  MwCardRadioOptionConfiguration
    .create<TipoSpedizioneEnum>()
    .setImage(`${iconsBasePath}box.svg`)
    .setValue(TipoSpedizioneEnum.GiacenzaScatola)
    .setLabel(`Aggiungi scatola`),
  MwCardRadioOptionConfiguration
    .create<TipoSpedizioneEnum>()
    .setValue(TipoSpedizioneEnum.ComposizioneBox)
    .setImage(`${iconsBasePath}bottles.svg`)
    .setLabel(`Crea scatola personalizzata`)
];

@Component({
  selector: "app-shipment-type-selection",
  template: `
    <div class="radio-packaging-selector">
      <mw-card-radio-group [configuration]="mwCardRadioGroupConfiguration"></mw-card-radio-group>
    </div>`
})
export class ShipmentTypeSelectionComponent {

  control: FormControl = new FormControl(null, Validators.required);

  mwCardRadioGroupConfiguration: MwCardRadioGroupConfiguration<TipoSpedizioneEnum> = MwCardRadioGroupConfiguration
    .create<TipoSpedizioneEnum>()
    .setControl(this.control)
    .setOptions(ShipmentTypeCardRadioOptionConfigurations)
    .enableHorizontalView(true);

}
