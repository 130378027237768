import { ClienteModel } from "../../../core/classes/cliente";

export class AddressesDatatableConfiguration {

  cliente: ClienteModel | null = null;

  private constructor() {
  }

  public static create = () => new AddressesDatatableConfiguration();

  setCliente(value: ClienteModel | null) {

    this.cliente = value;

    if (!this.cliente?.nomeRiferimento) {

      if (!this.cliente) this.cliente = {} as ClienteModel;

      this.cliente.nomeRiferimento = [this.cliente.nome, this.cliente.cognome].filter(elm => !!elm).join(" ");

    }

    return this;

  }

}
