import { MediaMatcher } from "@angular/cdk/layout";
import { Router } from "@angular/router";
import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { Direction } from "@angular/cdk/bidi";
import { KeycloakService } from "keycloak-angular";

/** @title Responsive sidenav */
@Component({
  selector: "app-full-layout",
  templateUrl: "full.component.html",
  styleUrls: []
})
export class FullComponent implements OnDestroy {

  mobileQuery: MediaQueryList;

  dir: Direction = "ltr";
  dark = false;
  minisidebar = false;
  boxed = false;

  sidebarOpened = false;
  status = false;

  public selectedLanguage: any = {
    language: "Italian",
      code: "it",
      type: "IT",
      icon: "it"
  };

  public languages: any[] = [
    {
      language: "English",
      code: "en",
      type: "US",
      icon: "us"
    },
    {
      language: "Italian",
      code: "it",
      type: "IT",
      icon: "it"
    }
  ];

  // tslint:disable-next-line - Disables all
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public router: Router,
    private translate: TranslateService,
    private keycloakService: KeycloakService
  ) {

    this.mobileQuery = media.matchMedia("(min-width: 1100px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();

    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.dark = false;

  }

  clickEvent(): void {

    this.status = !this.status;

  }

  darkClick() {

    const body = document.getElementsByTagName("body")[0];
    body.classList.toggle("dark");

  }

  changeLanguage(lang: any): void {

    this.translate.use(lang.code);
    this.selectedLanguage = lang;

  }

  logout() {

    this.keycloakService
      .logout();

  }

  ngOnDestroy(): void {

    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

}
